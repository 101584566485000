import cx from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { ACCOUNT_BALANCE_PATH } from 'webReservations/paths';
import typography from '~styles/typography.scss';
import { Modal } from '../modal/Modal';
import { deleteBankAccount } from './apiHelpers';
import styles from './BankAccountModal.scss';

interface DisconnectBankAccountModalProps {
  closeModal: () => void;
}

export const DisconnectBankAccountModal = ({
  closeModal,
}: DisconnectBankAccountModalProps) => {
  const [isDisconnectBankButtonDisabled, setIsDisconnectBankButtonDisabled] =
    useState(false);

  const navigate = useNavigate();

  const handleOnClickDisconnectBankAccount = async () => {
    setIsDisconnectBankButtonDisabled(true);
    const disconnectResponse = await deleteBankAccount();
    closeModal();
    navigate(ACCOUNT_BALANCE_PATH, {
      state: {
        plaidLinkError: isErrorResponse(disconnectResponse),
        attemptedToDisconnectBankAccount: true,
      },
    });
  };

  return (
    <Modal label="Disconnect Bank Account" onClose={closeModal}>
      <div className={styles.container}>
        <h1 className={typography.d3}>Disconnect Bank Account</h1>
        <div
          className={cx(
            typography.c2,
            styles.disconnectBankAccountModalMessage,
          )}
        >
          If you withdrew your funds from Peak recently, please confirm the
          transfer has completed before disconnecting your account.
          <br /> Are you sure you would like to remove your bank account?
        </div>
        <div className={styles.disconnectBankAccountModalActionContainer}>
          <Button
            isDisabled={isDisconnectBankButtonDisabled}
            label="Disconnect Bank"
            onClick={handleOnClickDisconnectBankAccount}
            useDeprecatedBreakpoint
            variant={ButtonVariants.Primary}
          />
          <Button
            className={styles.keepBankAccountConnectedButton}
            label="Keep Bank Account Connected"
            onClick={closeModal}
            useDeprecatedBreakpoint
            variant={ButtonVariants.Primary}
          />
        </div>
      </div>
    </Modal>
  );
};
