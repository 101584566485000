// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (min-width: 720px){.DeprecatedButtonStyles__deprecatedBtn___WPzvJ{font-size:.875rem;font-weight:600;height:2.5rem;line-height:.875rem}}", "",{"version":3,"sources":["webpack://./src/shared/styles/mixins.scss","webpack://./src/shared/components/button/DeprecatedButtonStyles.scss"],"names":[],"mappings":"AAwBI,0CCtBJ,+CAEI,iBAAA,CACA,eAAA,CACA,aAAA,CACA,mBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deprecatedBtn": "DeprecatedButtonStyles__deprecatedBtn___WPzvJ"
};
export default ___CSS_LOADER_EXPORT___;
