import { useEffect, useState } from 'react';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { useUserContext } from '../context/userContext';
import type { ReservationWithDetails } from '../reservationDetails/apiHelpers';
import { getReservation } from '../reservationDetails/apiHelpers';
import { useError } from './useError';

interface UseReservationDetails {
  fetchReservation: () => void;
  isReservationLoading: boolean;
  reservation?: ReservationWithDetails;
}

export const useReservationDetails = (
  reservationId?: string,
): UseReservationDetails => {
  const { isAuthenticated } = useUserContext();
  const [reservation, setReservation] = useState<ReservationWithDetails>();
  const [isReservationLoading, setIsReservationLoading] = useState(true);
  const setError = useError();

  const fetchReservation = (): void => {
    if (!reservationId) return;

    setIsReservationLoading(true);

    void (async () => {
      try {
        const response = await getReservation(reservationId);
        if (isSuccessResponse(response)) {
          setReservation(response);
        }
      } catch (e) {
        setError(e);
      }
      setIsReservationLoading(false);
    })();
  };

  useEffect(() => {
    fetchReservation();
  }, [isAuthenticated]);

  return { fetchReservation, isReservationLoading, reservation };
};
