import { Button, ButtonVariants } from '@components/button/Button';
import { TimeFilterPopover } from '@shared/components/timeFilter/TimeFilterPopover';
import { sendGa4AvailabilityFilterTimeApplyEvent } from 'webReservations/availability/gaHelpers';
import { useAvailabilityContext } from 'webReservations/context/availabilityContext';
import { useRestaurantContext } from 'webReservations/context/restaurantContext';
import { useModalContext } from '../context/modalContext';
import { DateAndGuestCountFilterPopover } from './DateAndGuestCountFilterPopover';
import styles from './MobileFiltersModal.scss';
import './Datetime.overrides.css';
import { NoTimes } from './NoTimes';

export const MobileFiltersModal = () => {
  const { availableTimes, selectedTime, setSelectedTime } =
    useAvailabilityContext();
  const { closeModal } = useModalContext();
  const { restaurantDetails } = useRestaurantContext();

  const handleTimeClick = (time: string): void => {
    setSelectedTime(time);

    sendGa4AvailabilityFilterTimeApplyEvent({
      restaurantName: restaurantDetails.name,
    });
  };

  return (
    <div
      aria-label="Filter Availabilities"
      className={styles.container}
      role="dialog"
    >
      <DateAndGuestCountFilterPopover />
      <TimeFilterPopover
        availableTimes={availableTimes}
        noTimes={<NoTimes />}
        selectedTime={selectedTime}
        setSelectedTime={handleTimeClick}
      />
      <Button
        className={styles.button}
        label="Close"
        onClick={closeModal}
        useDeprecatedBreakpoint
        variant={ButtonVariants.Primary}
      />
    </div>
  );
};
