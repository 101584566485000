import cx from 'classnames';
import { IconButton } from '@components/iconButton/IconButton';
import styles from './GuestCountPicker.scss';

const MIN = 1;
const STEP = 1;

interface GuestCountPickerProps {
  max: number;
  className?: string;
  guestCount: number;
  onChange: (args: number) => void;
  mode?: 'dark' | 'light';
}

export const GuestCountPicker = ({
  max,
  onChange,
  guestCount,
  className = '',
  mode = 'light',
}: GuestCountPickerProps) => {
  const handleStepDown = () => {
    const newGuestCount = guestCount - STEP;
    if (newGuestCount >= MIN) {
      onChange(newGuestCount);
    }
  };

  const handleStepUp = () => {
    const newGuestCount = guestCount + STEP;
    if (newGuestCount <= max) {
      onChange(newGuestCount);
    }
  };

  const containerStyles = cx({
    [styles.container]: true,
    [styles.dark]: mode === 'dark',
    [styles.light]: mode === 'light',
    [className]: className,
  });

  const buttonStyles = cx({
    [styles.button]: true,
    [styles.buttonLight]: mode === 'light',
  });

  return (
    <div className={containerStyles} data-testid="guest-count-picker">
      <div className={styles.label}>Guests</div>
      <div className={styles.filterRow}>
        <IconButton
          className={buttonStyles}
          iconName="minus"
          ariaLabel="decrease number of guests"
          onClick={handleStepDown}
          disabled={guestCount <= MIN}
        />
        <div className={styles.guestCount}>{guestCount}</div>
        <IconButton
          className={buttonStyles}
          ariaLabel="increase number of guests"
          iconName="plus"
          onClick={handleStepUp}
          disabled={guestCount >= max}
        />
      </div>
    </div>
  );
};
