import { parseISO } from 'date-fns';
import { useMemo } from 'react';
import ReactCalendar from 'react-calendar';
import type { TileArgs } from 'react-calendar';
import { Icon } from '@components/icon/Icon';
import { toISODateFormat } from '@utils/date';
import { useAvailabilityContext } from '../context/availabilityContext';
import { useAvailableDates } from '../hooks/useAvailableDates';
import './Calendar.overrides.css';
import type { AvailableDatesForRestaurant } from './apiHelpers';

interface CalendarProps {
  currentDate: string;
  setCurrentDate: (newDate: string) => void;
}

export const reduceAvailableDates = (
  availableDates: AvailableDatesForRestaurant,
  guestCount: number,
): string[] =>
  Object.keys(availableDates).filter((date) => {
    const { minGuests, maxGuests } = availableDates[date];
    return guestCount >= minGuests && guestCount <= maxGuests;
  });

export const Calendar = ({ currentDate, setCurrentDate }: CalendarProps) => {
  const { selectedGuestCount } = useAvailabilityContext();
  const { availableDates } = useAvailableDates();
  const dates = useMemo(
    () => reduceAvailableDates(availableDates, selectedGuestCount),
    [availableDates, selectedGuestCount],
  );

  const tileDisabledHandler = ({ date }: TileArgs) =>
    !dates.includes(toISODateFormat(date));

  return (
    <ReactCalendar
      calendarType="gregory"
      className="dark-mode"
      maxDetail="month"
      minDetail="month"
      next2Label={null}
      nextAriaLabel="Next Month"
      nextLabel={<Icon name="arrowLeft" className="arrowRight" />}
      onChange={(value) =>
        value instanceof Date && setCurrentDate(toISODateFormat(value))
      }
      prev2Label={null}
      prevAriaLabel="Previous Month"
      prevLabel={<Icon name="arrowLeft" className="arrowLeft" />}
      showNeighboringMonth={false}
      tileDisabled={tileDisabledHandler}
      value={parseISO(currentDate)}
      view="month"
    />
  );
};
