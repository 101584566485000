import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SnapshotFloorPlan } from '@components/floorPlan/SnapshotFloorPlan';
import { LoadingPage } from '@components/LoadingPage';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { mapToReservationStatus } from 'webReservations/reservations/statusHelpers';
import { NotFoundPage } from '../errors/NotFound/NotFoundPage';
import { useReservationDetails } from '../hooks/useReservationDetails';
import { getTopOffer } from './apiHelpers';
import { DetailsPageView } from './DetailsPageView';
import { ReservationDetailsPanel } from './ReservationDetailsPanel';

export const ReservationDetailsPage = () => {
  const { reservationId } = useParams();
  const [isReservationDetailsLoading, setIsReservationDetailsLoading] =
    useState(true);
  const [topOffer, setTopOffer] = useState({});
  useDocumentTitle('My Reservation Details | Peak Reservations');

  const { fetchReservation, reservation, isReservationLoading } =
    useReservationDetails(reservationId);

  useEffect(() => {
    if (!reservationId) return;

    const fetchReservationDetails = (): void => {
      void (async () => {
        setIsReservationDetailsLoading(true);
        if (reservation) {
          if (reservation.status === 'CONFIRMED') {
            setTopOffer(await getTopOffer(reservationId));
          }
        }
        setIsReservationDetailsLoading(false);
      })();
    };

    fetchReservationDetails();
  }, [reservation?.id]);

  if (isReservationLoading || isReservationDetailsLoading) {
    return <LoadingPage />;
  }

  if (!reservation || !reservationId) {
    return <NotFoundPage />;
  }

  return (
    <DetailsPageView
      floorPlanComponent={<SnapshotFloorPlan reservationId={reservationId} />}
      status={mapToReservationStatus(reservation.status)}
      restaurant={reservation.restaurant}
      date={reservation.date}
      guestCount={reservation.guestCount}
      listingPublicName={reservation.listing.publicName}
      time={reservation.time}
    >
      <ReservationDetailsPanel
        fetchReservation={fetchReservation}
        reservation={reservation}
        reservationId={reservationId}
        topOffer={topOffer}
      />
    </DetailsPageView>
  );
};
