// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationTabs__container___hwOPk{margin-top:32px}@media only screen and (max-width: 720px){.ReservationTabs__container___hwOPk{margin-top:0}}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/ReservationTabs.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,oCACE,eAAA,CCaE,0CDdJ,oCAII,YAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationTabs__container___hwOPk"
};
export default ___CSS_LOADER_EXPORT___;
