import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import { ModalTypes, useModalContext } from '../context/modalContext';
import styles from './SubNavLink.scss';

export const HelpSubNavButton = () => {
  const { openModal } = useModalContext();

  return (
    <button
      type="button"
      onClick={() => openModal(ModalTypes.USER_EDUCATION)}
      aria-label="Help"
      className={cx(styles.button, styles.helpButton)}
    >
      <Icon name="questionMark" />
      <span className={cx(typography.t1, styles.helpText)}>Help</span>
    </button>
  );
};
