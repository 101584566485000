import cx from 'classnames';
import type { MouseEvent } from 'react';
import { Tooltip } from 'react-tooltip';
import type { AnyIcon } from '../icon/Icon';
import { Icon } from '../icon/Icon';
import styles from './InteractiveFloorPlanTable.scss';
import { calculateContainerStyle, calculateIconStyle } from './utils';
import 'react-tooltip/dist/react-tooltip.css';

const SELECTABLE_OPACITY = 1;
const UNSELECTABLE_OPACITY = 0.3;

export interface TableIconCommonProps {
  iconName: AnyIcon;
  iconWidthScale: string;
  left: string;
  name: string;
  orientation: string;
  tableIconScale: number;
  testId?: string;
  top: string;
}

export interface InteractiveFloorPlanTableProps extends TableIconCommonProps {
  errorState?: boolean;
  handleTableOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  isHighlighted?: boolean;
  isSelectable?: boolean;
  pulse?: boolean;
  unselectableTooltipText?: string;
}

export const InteractiveFloorPlanTable = ({
  errorState = false,
  handleTableOnClick,
  iconName,
  iconWidthScale,
  isHighlighted,
  isSelectable,
  left,
  name,
  orientation,
  pulse = false,
  tableIconScale,
  testId,
  top,
  unselectableTooltipText = 'This table does not fit your current guest, date, or time needs.',
}: InteractiveFloorPlanTableProps) => {
  const buttonStyles = cx({
    [styles.button]: true,
    [styles.clickable]: handleTableOnClick,
    [styles.pulse]: pulse,
  });

  const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (handleTableOnClick) {
      handleTableOnClick(event);
    }
  };
  let buttonLabel = 'Unavailable table';
  if (isHighlighted) {
    buttonLabel = 'Unselect table';
  }
  if (!isHighlighted && isSelectable) {
    buttonLabel = 'Select table';
  }

  const iconSuffix = () => {
    if (errorState) return 'Error';
    if (isHighlighted) return 'Selected';
    return '';
  };

  const newIconName = `${iconName}${iconSuffix()}` as AnyIcon;

  return (
    <div
      className={styles.container}
      data-testid={testId}
      style={calculateContainerStyle({
        left,
        tableIconScale,
        top,
      })}
    >
      <button
        aria-label={buttonLabel}
        className={buttonStyles}
        data-tooltip-id={name}
        name={name}
        onClick={handleOnClick}
        type="button"
      >
        <Icon
          name={newIconName}
          style={{
            opacity: isSelectable ? SELECTABLE_OPACITY : UNSELECTABLE_OPACITY,
            ...calculateIconStyle({
              iconWidthScale,
              orientation,
              tableIconScale,
            }),
          }}
          testId={`icon-${newIconName}`}
        />
        {!isSelectable && (
          <Tooltip
            className={styles.unselectableToolTip}
            content={unselectableTooltipText}
            id={name}
            openOnClick
          />
        )}
      </button>
    </div>
  );
};
