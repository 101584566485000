// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalActions__container___bXn1P{display:flex;flex-direction:column-reverse;gap:1rem;justify-content:center;margin-top:4rem}@media only screen and (min-width: 768px){.ModalActions__container___bXn1P{flex-direction:row}}.ModalActions__container___bXn1P button{width:100%}@media only screen and (min-width: 768px){.ModalActions__container___bXn1P button{width:14.5rem}}.ModalActions__stack___jW_56{align-items:center;flex-direction:column-reverse}", "",{"version":3,"sources":["webpack://./src/shared/components/modal/ModalActions.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,iCACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,sBAAA,CACA,eAAA,CC8BE,0CDnCJ,iCAQI,kBAAA,CAAA,CAGF,wCACE,UAAA,CCuBA,0CDxBF,wCAII,aAAA,CAAA,CAKN,6BACE,kBAAA,CACA,6BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ModalActions__container___bXn1P",
	"stack": "ModalActions__stack___jW_56"
};
export default ___CSS_LOADER_EXPORT___;
