// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationsLayout__container___fsW7g{background-color:var(--primary400);margin:0 auto;max-width:var(--deprecatedPageContentMaxWidth)}@media only screen and (max-width: 720px){.ReservationsLayout__container___fsW7g{align-items:center;display:flex;flex-direction:column;margin:0 16px 16px;padding:0}}@media only screen and (min-width: 390px){.ReservationsLayout__container___fsW7g{margin:0 32px 16px;padding:16px 0}}@media only screen and (min-width: 1280px){.ReservationsLayout__container___fsW7g{margin:32px auto 16px}}@media only screen and (max-width: 720px){.ReservationsLayout__title___yAnfP{margin-top:32px}}.ReservationsLayout__tabs___iwOJw{margin-top:24px;border-bottom:1px solid var(--hairline);display:flex;flex-direction:row;column-gap:16px;width:100%}@media only screen and (max-width: 720px){.ReservationsLayout__tabs___iwOJw{margin-top:0}}.ReservationsLayout__tab___zE3o1{color:var(--white);padding:16px;margin-bottom:-1px}@media only screen and (max-width: 720px){.ReservationsLayout__tab___zE3o1{width:100%;text-align:center}}.ReservationsLayout__tab___zE3o1.ReservationsLayout__isActive___gYrjC{border-bottom:1px solid var(--accent300)}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/ReservationsLayout.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,uCACE,kCAAA,CACA,aAAA,CACA,8CAAA,CCWE,0CDdJ,uCAMI,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,SAAA,CAAA,CCQA,0CDlBJ,uCAcI,kBAAA,CACA,cAAA,CAAA,CCeA,2CD9BJ,uCAmBI,qBAAA,CAAA,CCLA,0CDSJ,mCAEI,eAAA,CAAA,CAIJ,kCACE,eAAA,CACA,uCAAA,CACA,YAAA,CACA,kBAAA,CACA,eAAA,CACA,UAAA,CCrBE,0CDeJ,kCASI,YAAA,CAAA,CAIJ,iCACE,kBAAA,CACA,YAAA,CACA,kBAAA,CC/BE,0CD4BJ,iCAMI,UAAA,CACA,iBAAA,CAAA,CAGF,sEACE,wCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationsLayout__container___fsW7g",
	"title": "ReservationsLayout__title___yAnfP",
	"tabs": "ReservationsLayout__tabs___iwOJw",
	"tab": "ReservationsLayout__tab___zE3o1",
	"isActive": "ReservationsLayout__isActive___gYrjC"
};
export default ___CSS_LOADER_EXPORT___;
