// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZoomControls__container___rSfXe{display:flex;justify-content:center;align-items:stretch;border-radius:50px;border:1px solid var(--hairline);position:absolute;top:0;right:0;z-index:var(--z_zoomControls);background-color:var(--primary400)}.ZoomControls__container___rSfXe .ZoomControls__button___R4mz8{background:none;border:none;cursor:pointer;padding:6px 12px}.ZoomControls__container___rSfXe .ZoomControls__button___R4mz8 .ZoomControls__icon___zaym9{stroke:var(--white);width:16px}.ZoomControls__container___rSfXe .ZoomControls__button___R4mz8:disabled{cursor:not-allowed}.ZoomControls__container___rSfXe .ZoomControls__button___R4mz8:disabled .ZoomControls__icon___zaym9{stroke:#777}.ZoomControls__container___rSfXe .ZoomControls__divider___Rh5jA{background-color:var(--hairline);width:1px}", "",{"version":3,"sources":["webpack://./src/shared/components/floorPlan/ZoomControls.scss"],"names":[],"mappings":"AAAA,iCACE,YAAA,CACA,sBAAA,CACA,mBAAA,CACA,kBAAA,CACA,gCAAA,CACA,iBAAA,CACA,KAAA,CACA,OAAA,CACA,6BAAA,CACA,kCAAA,CAEA,+DACE,eAAA,CACA,WAAA,CACA,cAAA,CACA,gBAAA,CAEA,2FACE,mBAAA,CACA,UAAA,CAGF,wEACE,kBAAA,CAEA,oGACE,WAAA,CAKN,gEACE,gCAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ZoomControls__container___rSfXe",
	"button": "ZoomControls__button___R4mz8",
	"icon": "ZoomControls__icon___zaym9",
	"divider": "ZoomControls__divider___Rh5jA"
};
export default ___CSS_LOADER_EXPORT___;
