import { type CancellationPolicy } from '@shared/reservations/types';
import { type ApiResponse } from '@shared/types/apiHelpers';
import { type IconNameType } from '@shared/types/floorPlans';
import { type RestaurantStatus } from '@shared/types/restaurants';
import API from '../api/apiClient';

export interface IRestaurantDetails {
  address: string;
  cancellationPolicy: CancellationPolicy | null;
  city: string;
  description: string;
  id: string;
  locationSlug: string;
  maxReservationGuests: number;
  name: string;
  nameSlug: string;
  phone: string;
  postalCode: string;
  schedule: number;
  state: string;
  status: RestaurantStatus;
  street: string;
  timezone: string;
  website: string;
}

export interface IAvailabilityListing {
  iconName: IconNameType;
  id: string;
  isCommunal: boolean;
  publicName: string;
}

export interface IAvailability {
  isBiddable: boolean;
  isBuyable: boolean;
  guestCount: number;
  purchasePrice: number;
  listing: IAvailabilityListing;
  time: string;
}

export interface IRestaurantEvent {
  description: string;
}

export interface Closure {
  date: string;
  id: string;
  restaurantId: string;
}

export const getRestaurantDetailsBySlug = async (
  locationSlug: string,
  nameSlug: string,
): Promise<ApiResponse<IRestaurantDetails>> => {
  const response = await API.get(`/restaurants/${locationSlug}/${nameSlug}`);
  return response.json();
};

export const getRestaurantDetailsById = async (
  restaurantId: string,
): Promise<ApiResponse<IRestaurantDetails>> => {
  const response = await API.get(`/restaurants/${restaurantId}`);
  return response.json();
};

export const getAvailabilities = async (
  restaurantId: string,
  date: string,
): Promise<IAvailability[]> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/availabilities/${date}`,
  );

  return response.json();
};

export const getEventForRestaurantIdAndDate = async (
  restaurantId: string,
  date: string,
): Promise<ApiResponse<IRestaurantEvent>> => {
  const response = await API.get(`/restaurants/${restaurantId}/events/${date}`);

  return response.json();
};

export const getClosureForDate = async (
  restaurantId: string,
  date: string,
): Promise<Closure[]> => {
  const response = await API.get(`/closures/${restaurantId}?date=${date}`);

  return response.json();
};
