import cx from 'classnames';
import { Divider } from '@components/divider/Divider';
import { GuestCountPicker } from '@components/guestCountPicker/GuestCountPicker';
import { useRestaurantContext } from 'webReservations/context/restaurantContext';
import typography from '~styles/typography.scss';
import { useAvailabilityContext } from '../context/availabilityContext';
import {
  sendGa4AvailabilityFilterDateUpdateEvent,
  sendGa4AvailabilityFilterGuestUpdateEvent,
} from '../availability/gaHelpers';
import { Calendar } from './Calendar';
import styles from './DateAndGuestCountFilterPill.scss';
import { MaxGuestCountCopy } from './MaxGuestCountCopy';

export const DateAndGuestCountFilterPopover = () => {
  const { restaurantDetails } = useRestaurantContext();
  const {
    selectedDate,
    setSelectedDate,
    selectedGuestCount,
    setSelectedGuestCount,
  } = useAvailabilityContext();

  let noTimes;

  if (selectedGuestCount > restaurantDetails.maxReservationGuests) {
    noTimes = <MaxGuestCountCopy />;
  }

  return (
    <div
      className={styles.popover}
      data-testid="date-and-guest-count-filter-popover"
    >
      <GuestCountPicker
        className={styles.guestCountPicker}
        guestCount={selectedGuestCount}
        max={restaurantDetails.maxReservationGuests + 1}
        onChange={(newGuestCount) => {
          setSelectedGuestCount(newGuestCount);
          sendGa4AvailabilityFilterGuestUpdateEvent({
            restaurantName: restaurantDetails.name,
            guestCount: newGuestCount,
          });
        }}
        mode="dark"
      />
      <Divider className={styles.divider} />
      {noTimes || (
        <>
          <h1 className={cx(styles.selectDate, typography.h7)}>Select Date</h1>
          <Calendar
            currentDate={selectedDate}
            setCurrentDate={(newDate: string) => {
              setSelectedDate(newDate);
              sendGa4AvailabilityFilterDateUpdateEvent({
                restaurantName: restaurantDetails.name,
                date: newDate,
              });
            }}
          />
        </>
      )}
    </div>
  );
};
