// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubNav__subNav___JLLAo{display:flex;justify-content:flex-start;align-items:center;height:100%;padding:0 16px}@media only screen and (min-width: 720px){.SubNav__subNav___JLLAo{padding:0 40px}}", "",{"version":3,"sources":["webpack://./src/webReservations/restaurant/SubNav.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,wBACE,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,WAAA,CACA,cAAA,CCiBE,0CDtBJ,wBAQI,cAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subNav": "SubNav__subNav___JLLAo"
};
export default ___CSS_LOADER_EXPORT___;
