import Close from '@assets/icons/close.svg';
import Menu from '@assets/icons/menu.svg';
import { useDrawerContext } from '../context/drawerContext';
import styles from './NavigationMobile.scss';

export const NavigationMobile = () => {
  const { isOpen, openDrawer, closeDrawer } = useDrawerContext();

  return isOpen ? (
    <button
      className={styles.button}
      onClick={closeDrawer}
      aria-label="Close Menu"
    >
      <Close className={styles.closeIcon} />
    </button>
  ) : (
    <button
      className={styles.button}
      onClick={openDrawer}
      aria-label="Open Menu"
    >
      <Menu className={styles.menuIcon} />
    </button>
  );
};
