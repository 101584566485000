/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';

const MODALS_START = 0;
const MODALS_END = -1;

export enum AuthModalTypes {
  SIGN_IN = 'signIn',
  SIGN_IN_OTP = 'signInOtp',
  SIGN_UP = 'signUp',
  SIGN_UP_OTP = 'signUpOtp',
  UNREGISTERED_USER_LOGIN = 'unregisteredUserLogin',
}

export interface AuthModalConfiguration {
  modalType: AuthModalTypes;
  props?: any;
}

interface AuthModalContextType {
  closeModal: () => void;
  modals: AuthModalConfiguration[];
  nextModal: (modalType: AuthModalTypes, props?: any) => void;
  openModal: (modalType: AuthModalTypes, props?: any) => void;
  previousModal: () => void;
}

const AuthModalContext = createContext<AuthModalContextType | null>(null);
AuthModalContext.displayName = 'Auth Modal';

export const useAuthModalContext = () => useDefinedContext(AuthModalContext);

interface AuthModalContextProviderProps {
  children: ReactNode;
}

export const AuthModalContextProvider = ({
  children,
}: AuthModalContextProviderProps) => {
  const [modals, setModals] = useState<AuthModalConfiguration[]>([]);

  const closeModal = () => {
    setModals([]);
  };

  const previousModal = () => {
    setModals(modals.slice(MODALS_START, MODALS_END));
  };

  const openModal = (modalType: AuthModalTypes, props?: any) => {
    setModals([{ modalType, props }]);
  };

  const nextModal = (modalType: AuthModalTypes, props?: any) => {
    setModals([...modals, { modalType, props }]);
  };

  const value = useMemo<AuthModalContextType>(
    () => ({
      closeModal,
      modals,
      nextModal,
      openModal,
      previousModal,
    }),
    [modals],
  );

  return (
    <AuthModalContext.Provider value={value}>
      {children}
    </AuthModalContext.Provider>
  );
};
