// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MaxGuestCountCopy__heading___CwduV{margin:24px 0;display:block}.MaxGuestCountCopy__heading___CwduV:first-child{margin-top:8px}.MaxGuestCountCopy__maxGuestsMessage___gGSsO{color:var(--white70);font-weight:300;padding-right:80px;width:350px}.MaxGuestCountCopy__linkText___YD8iw{color:var(--white70)}", "",{"version":3,"sources":["webpack://./src/webReservations/filters/MaxGuestCountCopy.scss"],"names":[],"mappings":"AAAA,oCACE,aAAA,CACA,aAAA,CAEA,gDACE,cAAA,CAIJ,6CACE,oBAAA,CACA,eAAA,CACA,kBAAA,CACA,WAAA,CAGF,qCACE,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "MaxGuestCountCopy__heading___CwduV",
	"maxGuestsMessage": "MaxGuestCountCopy__maxGuestsMessage___gGSsO",
	"linkText": "MaxGuestCountCopy__linkText___YD8iw"
};
export default ___CSS_LOADER_EXPORT___;
