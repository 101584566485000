// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationCardActions__actions___WnD00{align-items:flex-end;display:flex;flex-direction:column;justify-content:flex-end;margin-left:auto;padding:0;width:176px}@media only screen and (max-width: 720px){.ReservationCardActions__hasActions___AWW4L{align-items:stretch;border-top:1px solid var(--hairline);padding:16px 0;row-gap:8px;margin-left:unset;width:100%}}.ReservationCardActions__listPriceButton___AUTVz{color:var(--soldBlue);margin-bottom:auto}@media only screen and (max-width: 720px){.ReservationCardActions__listPriceButton___AUTVz{height:32px;color:var(--white);order:2;font-size:12px}}.ReservationCardActions__sellNowButton___LAZis{font-size:12px;margin:auto 16px 24px 0}@media only screen and (max-width: 720px){.ReservationCardActions__sellNowButton___LAZis{height:32px;margin:0 24px;order:1;padding:0 16px}}@media only screen and (min-width: 800px){.ReservationCardActions__sellNowButton___LAZis{margin:auto 24px 24px 0}}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/ReservationCardActions.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,yCACE,oBAAA,CACA,YAAA,CACA,qBAAA,CACA,wBAAA,CACA,gBAAA,CACA,SAAA,CACA,WAAA,CCOE,0CDJJ,4CAEI,mBAAA,CACA,oCAAA,CACA,cAAA,CACA,WAAA,CACA,iBAAA,CACA,UAAA,CAAA,CAIJ,iDACE,qBAAA,CACA,kBAAA,CCTE,0CDOJ,iDAKI,WAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA,CAAA,CAIJ,+CACE,cAAA,CACA,uBAAA,CCrBE,0CDmBJ,+CAKI,WAAA,CACA,aAAA,CACA,OAAA,CACA,cAAA,CAAA,CCfA,0CDOJ,+CAYI,uBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "ReservationCardActions__actions___WnD00",
	"hasActions": "ReservationCardActions__hasActions___AWW4L",
	"listPriceButton": "ReservationCardActions__listPriceButton___AUTVz",
	"sellNowButton": "ReservationCardActions__sellNowButton___LAZis"
};
export default ___CSS_LOADER_EXPORT___;
