import cx from 'classnames';
import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { CurrencyInput } from '@components/currencyInput/CurrencyInput';
import { MAXIMUM_LIST_PRICE_IN_DOLLARS } from '@shared/reservations/constants';
import { wholeDollarsToCents } from '@utils/currency';
import typography from '~styles/typography.scss';
import { RETRYABLE_ERROR_MESSAGE } from '../errors/messages';
import { Modal } from '../modal/Modal';
import { updateListPrice } from './apiHelpers';
import styles from './SetListPriceModal.scss';
import sharedStyles from './SharedListPriceModal.scss';

interface SetListPriceModalProps {
  closeModal: () => void;
  onSubmit: () => Promise<void>;
  reservationId: string;
}

export const SetListPriceModal = ({
  closeModal,
  onSubmit,
  reservationId,
}: SetListPriceModalProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [listPrice, setListPrice] = useState('');

  const handleSubmitListPrice = async () => {
    setIsLoading(true);
    const success = await updateListPrice(
      reservationId,
      wholeDollarsToCents(Number(listPrice)),
    );
    if (success) {
      onSubmit();
      closeModal();
    } else {
      setIsLoading(false);
      setErrorMessage(RETRYABLE_ERROR_MESSAGE);
    }
  };

  return (
    <Modal label="Set List Price">
      <div className={sharedStyles.container}>
        <h1 className={cx(typography.d3, styles.heading)}>List Reservation</h1>
        <p className={cx(typography.c2, styles.instructions)}>
          Can&apos;t make your reservation?{' '}
          <span className={styles.mobileLineBreak}>
            <br />
          </span>
          Enter your price below and sell it!
        </p>
        <div className={sharedStyles.listPriceForm}>
          <CurrencyInput
            className={sharedStyles.listPriceInput}
            id="listPriceInput"
            label="List price"
            maxValue={MAXIMUM_LIST_PRICE_IN_DOLLARS}
            name="listPriceInput"
            onChange={setListPrice}
            value={listPrice}
          />
        </div>
        <div className={sharedStyles.actions}>
          <Button
            className={sharedStyles.button}
            isDisabled={!listPrice}
            isLoading={isLoading}
            label="List Reservation"
            onClick={handleSubmitListPrice}
            type="submit"
            useDeprecatedBreakpoint
            variant={ButtonVariants.Primary}
          />
        </div>
        <div className={sharedStyles.error}>{errorMessage}</div>
      </div>
    </Modal>
  );
};
