// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SignInModal__container___rffhT{display:flex;flex-direction:column;justify-content:start}.SignInModal__container___rffhT>p{color:var(--white70);margin:1rem 0 .5rem}@media only screen and (min-width: 768px){.SignInModal__container___rffhT>p{margin:2rem 0 .5rem}}.SignInModal__container___rffhT>p a{color:inherit;font-size:inherit;text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/webReservations/auth/SignInModal.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,gCACE,YAAA,CACA,qBAAA,CACA,qBAAA,CAEA,kCACE,oBAAA,CACA,mBAAA,CC4BA,0CD9BF,kCAKI,mBAAA,CAAA,CAGF,oCACE,aAAA,CACA,iBAAA,CACA,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SignInModal__container___rffhT"
};
export default ___CSS_LOADER_EXPORT___;
