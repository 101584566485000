import type { ReactElement } from 'react';
import { formatPhone } from '@shared/utils/formatPhone';
import { todayInTimezone } from '@utils/date';
import typography from '~styles/typography.scss';
import { useAvailabilityContext } from '../context/availabilityContext';
import { useRestaurantContext } from '../context/restaurantContext';
import { MaxGuestCountCopy } from './MaxGuestCountCopy';
import { NoAvailabilityCopy } from './NoAvailabilityCopy';
import styles from './TimeFilterPill.scss';

export const NoTimes = () => {
  const {
    availableTimes,
    hasAvailabilitiesOnGivenDay,
    isClosedToday,
    selectedDate,
    selectedGuestCount,
  } = useAvailabilityContext();
  const { restaurantDetails } = useRestaurantContext();

  const hasNoAvailabilitiesWhatsoeverOnGivenDay = !hasAvailabilitiesOnGivenDay;
  const hasNoAvailableTimesForSelectedGuestCount = !availableTimes.length;
  const selectedGuestCountExceedsRestaurantMax =
    selectedGuestCount > restaurantDetails.maxReservationGuests;
  const restaurantIsClosedToday =
    isClosedToday &&
    selectedDate === todayInTimezone(restaurantDetails.timezone);
  let noTimesElement: ReactElement = <>No times are available.</>;

  if (restaurantIsClosedToday) {
    noTimesElement = (
      <span>
        Unfortunately, {restaurantDetails.name} is no longer accepting new
        reservations for this day. Please call{' '}
        <a
          className={styles.phoneLinkText}
          href={`tel:${restaurantDetails.phone}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className={typography.t1}>
            {formatPhone(restaurantDetails.phone)}
          </span>
        </a>{' '}
        for further assistance.
      </span>
    );
  } else if (hasNoAvailabilitiesWhatsoeverOnGivenDay) {
    noTimesElement = <NoAvailabilityCopy />;
  } else if (selectedGuestCountExceedsRestaurantMax) {
    noTimesElement = <MaxGuestCountCopy />;
  } else if (hasNoAvailableTimesForSelectedGuestCount) {
    noTimesElement = (
      <span>Can&apos;t find a table? Adjust date or guests.</span>
    );
  }

  return <div data-testid="no-available-times">{noTimesElement}</div>;
};
