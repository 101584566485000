import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { useUserContext } from '../context/userContext';

export interface SignOutModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

export const SignOutModal = ({ closeModal, isOpen }: SignOutModalProps) => {
  const { signOut } = useUserContext();

  const handleSignOutOnClick = () => signOut();

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="Are you sure you would like to sign&nbsp;out?"
      title="Sign Out"
    >
      <ModalActions>
        <Button
          label="Remain Logged In"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Sign Out"
          onClick={handleSignOutOnClick}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
