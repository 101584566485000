import cx from 'classnames';
import { useState } from 'react';
import { CurrencyInput } from '@components/currencyInput/CurrencyInput';
import { Divider } from '@components/divider/Divider';
import { StripeElements } from '@components/StripeElements';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import {
  CENTS_IN_DOLLAR,
  MAXIMUM_STRIPE_TRANSACTION_AMOUNT_IN_CENTS,
  wholeDollarsToCents,
} from '@utils/currency';
import { ReservationAttributesGrid } from 'webReservations/reservations/ReservationAttributesGrid';
import typography from '~styles/typography.scss';
import { useOfferCheckoutContext } from '../context/offerCheckoutContext';
import { useRestaurantDetails } from '../hooks/useRestaurantDetails';
import { DeprecatedLayout } from '../layout/DeprecatedLayout';
import {
  MAXIMUM_OFFER_AMOUNT_IN_DOLLARS,
  MINIMUM_OFFER_AMOUNT_IN_DOLLARS,
} from '../offers/constants';
import { Address } from '../reservations/Address';
import { OfferCheckoutForm } from './OfferCheckoutForm';
import styles from './OfferCheckoutPage.scss';
import { OfferCheckoutReceipt } from './OfferCheckoutReceipt';
import { usePricingInfo } from './usePricingInfo';

const DEFAULT_PRICING_INFO = {
  fee: 0,
  tax: 0,
  total: 0,
};

export const OfferCheckoutPage = () => {
  useDocumentTitle('Checkout Offers | Peak Reservations');

  const {
    offerCheckout: { date, guestCount, publicName, time, restaurantId },
  } = useOfferCheckoutContext();
  const { isLoading, restaurantDetails } = useRestaurantDetails(restaurantId);
  const [offerInDollars, setOfferInDollars] = useState<string>('');
  const { pricingInfo, isLoading: isPricingInfoLoading } = usePricingInfo(
    restaurantDetails.postalCode,
    offerInDollars ? wholeDollarsToCents(Number(offerInDollars)) : undefined,
  );

  const handleAmountUpdate = (offerAmount: string) => {
    setOfferInDollars(offerAmount);
  };

  const isValidOfferAmount =
    pricingInfo &&
    pricingInfo.total >= MINIMUM_OFFER_AMOUNT_IN_DOLLARS * CENTS_IN_DOLLAR &&
    pricingInfo.total <= MAXIMUM_STRIPE_TRANSACTION_AMOUNT_IN_CENTS;

  if (isLoading) {
    return null;
  }

  return (
    <StripeElements>
      <DeprecatedLayout className={styles.layout}>
        <main className={styles.main}>
          <div className={styles.content}>
            <h1 className={cx(typography.d3, styles.title)}>Make an Offer</h1>
            <section className={styles.section}>
              <h2 className={cx(typography.h5, styles.sectionHead)}>
                Reservation
              </h2>
              <div className={styles.reservationContent}>
                <p className={cx(typography.d4, styles.restaurantName)}>
                  {restaurantDetails.name}
                </p>
                <Address value={restaurantDetails.address} />
                <ReservationAttributesGrid
                  date={date}
                  guestCount={guestCount}
                  publicName={publicName}
                  time={time}
                />
              </div>
            </section>
            <section className={styles.section}>
              <h2 className={cx(typography.h5, styles.sectionHead)}>Offer</h2>
              <div className={styles.offerContent}>
                <CurrencyInput
                  className={styles.offerInput}
                  id="offerInput"
                  label="Offer amount"
                  maxValue={MAXIMUM_OFFER_AMOUNT_IN_DOLLARS}
                  name="offerInput"
                  onChange={handleAmountUpdate}
                  placeholder="Offer amount"
                  value={offerInDollars}
                />
                <p className={typography.c3}>
                  This offer will not replace any existing offers you&nbsp;have.
                </p>
                <Divider />
                <OfferCheckoutReceipt
                  date={date}
                  fee={pricingInfo ? pricingInfo.fee : DEFAULT_PRICING_INFO.fee}
                  price={wholeDollarsToCents(parseInt(offerInDollars, 10))}
                  tax={pricingInfo ? pricingInfo.tax : DEFAULT_PRICING_INFO.tax}
                  total={
                    pricingInfo ? pricingInfo.total : DEFAULT_PRICING_INFO.total
                  }
                />
              </div>
            </section>
            <section className={styles.section}>
              <h2 className={cx(typography.h5, styles.sectionHead)}>
                Payment Method
              </h2>
              <div className={styles.paymentMethodContent}>
                <OfferCheckoutForm
                  disabled={!isValidOfferAmount || isPricingInfoLoading}
                  offerAmount={wholeDollarsToCents(
                    parseInt(offerInDollars, 10),
                  )}
                />
              </div>
            </section>
          </div>
        </main>
      </DeprecatedLayout>
    </StripeElements>
  );
};
