import {
  type AuthModalConfiguration,
  AuthModalTypes,
  useAuthModalContext,
} from '../context/authModalContext';
import { SignInModal } from '../auth/SignInModal';
import { SignInOTPModal } from '../auth/SignInOTPModal';
import { SignUpModal } from '../auth/SignUpModal';
import { SignUpOTPModal } from '../auth/SignUpOTPModal';
import { UnregisteredUserLoginModal } from '../auth/UnregisteredUserLoginModal';

export const AuthModalsContainer = () => {
  const { modals } = useAuthModalContext();

  return (
    <>
      {modals.map(({ modalType, props }: AuthModalConfiguration) => {
        switch (modalType) {
          case AuthModalTypes.SIGN_IN:
            return (
              <SignInModal
                key={JSON.stringify({ modalType, props })}
                {...props}
              />
            );
          case AuthModalTypes.SIGN_IN_OTP:
            return (
              <SignInOTPModal
                key={JSON.stringify({ modalType, props })}
                {...props}
              />
            );
          case AuthModalTypes.SIGN_UP:
            return (
              <SignUpModal
                key={JSON.stringify({ modalType, props })}
                {...props}
              />
            );
          case AuthModalTypes.SIGN_UP_OTP:
            return (
              <SignUpOTPModal
                key={JSON.stringify({ modalType, props })}
                {...props}
              />
            );
          case AuthModalTypes.UNREGISTERED_USER_LOGIN:
            return (
              <UnregisteredUserLoginModal
                key={JSON.stringify({ modalType, props })}
                {...props}
              />
            );
          default:
            throw new Error(`Invalid auth modal type ${modalType}`);
        }
      })}
    </>
  );
};
