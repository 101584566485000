import cx from 'classnames';
import { Link } from 'react-router-dom';
import { EmailSupport } from '@shared/components/emailSupport/EmailSupport';
import {
  ABOUT_US_PATH,
  FAQ_PATH,
  PRIVACY_POLICY_PATH,
  RESTAURANT_DEMO_PATH,
  TERMS_OF_SERVICE_PATH,
} from '../paths';
import styles from './Footer.scss';
import { sendGa4FooterScheduleDemoClickEvent } from './gaHelpers';

export const Footer = () => (
  <footer className={styles.footer}>
    <nav className={styles.links}>
      <Link className={styles.link} to={ABOUT_US_PATH}>
        About Peak
      </Link>
      <Link className={styles.link} to={TERMS_OF_SERVICE_PATH}>
        Terms of Service
      </Link>
      <Link className={styles.link} to={PRIVACY_POLICY_PATH}>
        Privacy Policy
      </Link>
      <EmailSupport
        className={cx(styles.link, styles.supportLink)}
        label="Support"
      />
      <Link className={styles.link} to={FAQ_PATH}>
        FAQ
      </Link>
    </nav>
    <div className={styles.partnerWithUs}>
      <span>Partner with Us</span>
      <Link
        className={cx(styles.link, styles.demoLink)}
        onClick={sendGa4FooterScheduleDemoClickEvent}
        to={RESTAURANT_DEMO_PATH}
      >
        Schedule a Demo
      </Link>
    </div>
  </footer>
);
