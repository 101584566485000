import cx from 'classnames';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button, ButtonVariants } from '@components/button/Button';
import { MOBILE } from '@shared/styles/breakpoints';
import { isErrorResponse } from '@shared/types/apiHelpers';
import typography from '~styles/typography.scss';
import { Modal } from '../modal/Modal';
import type { Offer } from './apiHelpers';
import { cancelOffer } from './apiHelpers';
import {
  sendGa4OfferWithdrawAbortEvent,
  sendGa4OfferWithdrawConfirmEvent,
} from './gaHelpers';
import styles from './WithdrawOfferModal.scss';

export interface WithdrawOfferModalProps {
  closeModal: () => void;
  offer: Offer;
  onSubmit: () => void;
}

export const WithdrawOfferModal = ({
  closeModal,
  offer,
  onSubmit,
}: WithdrawOfferModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const isTabletOrMobile = useMediaQuery({ maxWidth: MOBILE });

  const handleCancelCancel = () => {
    sendGa4OfferWithdrawAbortEvent({
      date: offer.date,
      offerAmount: offer.price,
      restaurantName: offer.restaurant.name,
      publicName: offer.listing.publicName,
      time: offer.time,
    });
    closeModal();
  };

  const handleCancelOffer = async () => {
    setIsLoading(true);
    sendGa4OfferWithdrawConfirmEvent({
      date: offer.date,
      offerAmount: offer.price,
      restaurantName: offer.restaurant.name,
      publicName: offer.listing.publicName,
      time: offer.time,
    });
    const response = await cancelOffer(offer.id);

    if (isErrorResponse(response)) {
      setError(response.message);
    } else {
      closeModal();
      onSubmit();
    }
    setIsLoading(false);
  };

  const titleStyles = isTabletOrMobile
    ? cx(typography.h3, styles.title)
    : cx(typography.d3, styles.title);

  return (
    <Modal label="Withdraw Offer" onClose={handleCancelCancel}>
      <div className={styles.container}>
        <h1 className={titleStyles}>Withdraw Offer</h1>
        <p className={cx(typography.c2, styles.info)}>
          This means reservation holders will no longer see your offer.
        </p>
        <p className={cx(typography.c2, styles.info)}>
          If you withdraw your offer, we will release the hold on your payment
          method and you will see that reflected in your financial statements
          within 5-10 business days
        </p>
        <div className={styles.buttonRow}>
          <Button
            className={styles.button}
            isDisabled={false}
            isLoading={isLoading}
            label="Keep Offer"
            onClick={handleCancelCancel}
            useDeprecatedBreakpoint
            variant={ButtonVariants.Tertiary}
          />
          <Button
            className={styles.button}
            isDisabled={false}
            isLoading={isLoading}
            label="Withdraw Offer"
            onClick={handleCancelOffer}
            useDeprecatedBreakpoint
            variant={ButtonVariants.Primary}
          />
        </div>
        <div className={styles.error}>{error}</div>
      </div>
    </Modal>
  );
};
