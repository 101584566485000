// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountsLayout__content___huUdk{padding:40px;display:flex;flex-direction:column;align-items:center;min-height:100vh}@media only screen and (max-width: 720px){.AccountsLayout__content___huUdk{margin-left:0;width:100%}}", "",{"version":3,"sources":["webpack://./src/webReservations/accounts/AccountsLayout.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,iCACE,YAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,gBAAA,CCSE,0CDdJ,iCAQI,aAAA,CACA,UAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "AccountsLayout__content___huUdk"
};
export default ___CSS_LOADER_EXPORT___;
