import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as apiHelpers from '../auth/apiHelpers';
import type { AuthenticatedUser, CheckAuthResponse } from '../auth/apiHelpers';
import { useError } from '../hooks/useError';
import { LANDING_PATH } from '../paths';
import { PLAID_LINK_TOKEN_KEY } from './plaidContext';

const noOp = (): void => undefined;

interface UserContextType {
  user?: AuthenticatedUser;
  isAuthenticated: boolean;
  isLoading: boolean;
  setUser: Dispatch<SetStateAction<AuthenticatedUser | undefined>>;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  signOut: () => Promise<void>;
}

const defaultContext: UserContextType = {
  user: undefined,
  isAuthenticated: false,
  isLoading: true,
  setUser: noOp,
  setIsAuthenticated: noOp,
  signOut: () => new Promise<void>(noOp),
};

const UserContext = createContext<UserContextType>(defaultContext);
UserContext.displayName = 'User';

export const useUserContext = () => useContext(UserContext);

interface UserContextProviderProps {
  children: ReactNode;
}

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<AuthenticatedUser>();
  const setError = useError();

  const signOut = async (): Promise<void> => {
    const response = await apiHelpers.signOut();

    if (response.ok) {
      localStorage.removeItem(PLAID_LINK_TOKEN_KEY);
      window.location.replace(LANDING_PATH);
    }
  };

  const fetchAuthState = async (): Promise<void> => {
    setIsLoading(true);

    try {
      const response: CheckAuthResponse = await apiHelpers.checkAuth();
      if (response.isAuthenticated) {
        setIsAuthenticated(true);
        setUser(response.user);
      } else {
        setIsAuthenticated(false);
        setUser(undefined);
      }
    } catch (e) {
      setError(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchAuthState();
  }, []);

  const value = useMemo<UserContextType>(
    () => ({
      user,
      setUser,
      isAuthenticated,
      isLoading,
      setIsAuthenticated,
      signOut,
    }),
    [user, isAuthenticated, isLoading],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
