import cx from 'classnames';
import { centsToDollar, centsToWholeDollar } from '@utils/currency';
import { isoToShortDate } from '@utils/date';
import typography from '~styles/typography.scss';
import type { ReservationWithDetails } from './apiHelpers';
import styles from './ReceiptDetails.scss';

interface ReceiptDetailsProps {
  reservation: ReservationWithDetails;
}

export const ReceiptDetails = ({ reservation }: ReceiptDetailsProps) => {
  const { soldForPrice, transaction } = reservation;

  return (
    <div className={styles.container}>
      <h4 className={cx(typography.h4, styles.title)}>Receipt</h4>
      <div className={styles.receiptDetails}>
        <span className={cx(typography.t1, styles.receiptDetailName)}>
          Purchase Date
        </span>
        <span className={cx(typography.t1, styles.receiptDetailValue)}>
          {isoToShortDate(transaction.purchaseDate)}
        </span>
      </div>
      <div className={styles.receiptDetails}>
        <span className={cx(typography.t1, styles.receiptDetailName)}>
          Purchase Price
        </span>
        <span className={cx(typography.t1, styles.receiptDetailValue)}>
          {centsToWholeDollar(transaction.price)}
        </span>
      </div>
      {transaction.refundAmount && (
        <div className={styles.receiptDetails}>
          <span className={cx(typography.t1, styles.receiptDetailName)}>
            Refund Amount
          </span>
          <span
            className={cx(typography.t1, styles.receiptDetailValue)}
            data-testid="refund-amount"
          >
            {centsToDollar(transaction.refundAmount)}
          </span>
        </div>
      )}
      {soldForPrice && (
        <div className={styles.receiptDetails}>
          <span className={cx(typography.t1, styles.receiptDetailName)}>
            Sold For
          </span>
          <span
            className={cx(typography.t1, styles.receiptDetailValue)}
            data-testid="sold-for-price"
          >
            {centsToWholeDollar(soldForPrice)}
          </span>
        </div>
      )}
      <div className={styles.receiptDetails}>
        <span className={cx(typography.t1, styles.receiptDetailName)}>
          Payment Type
        </span>
        <span className={cx(typography.t1, styles.receiptDetailValue)}>
          {transaction.paymentMethod}
        </span>
      </div>
      <div className={styles.receiptDetails}>
        <span className={cx(typography.t1, styles.receiptDetailName)}>
          Receipt Number
        </span>
        <span className={cx(typography.t1, styles.receiptDetailValue)}>
          {transaction.receiptNumber}
        </span>
      </div>
    </div>
  );
};
