export const MAXIMUM_STRIPE_TRANSACTION_AMOUNT_IN_CENTS = 99999999;
export const CENTS_IN_DOLLAR = 100;
export const ZERO = 0;
const MINIMUM_CENTS_AFFECTED_BY_COMPACT_NOTATION = 99950;

export const centsToDecimal = (value: number): number => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    minimumFractionDigits: 2,
    style: 'decimal',
  });

  return Number(currencyFormatter.format(value / CENTS_IN_DOLLAR));
};

export const centsToDollar = (value?: number): string => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    minimumFractionDigits: 2,
    style: 'currency',
  });
  if (!value && value !== ZERO) {
    return '--';
  }
  return currencyFormatter.format(value / CENTS_IN_DOLLAR);
};

export const centsToWholeDollar = (value?: number): string => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
  });
  if (!value && value !== ZERO) {
    return '--';
  }
  return currencyFormatter.format(value / CENTS_IN_DOLLAR);
};

export const wholeDollarsToCents = (value: number): number =>
  Number(value) * CENTS_IN_DOLLAR;

export const centsToCompactDollar = (value: number) => {
  if (value < MINIMUM_CENTS_AFFECTED_BY_COMPACT_NOTATION) {
    return centsToWholeDollar(value);
  }

  try {
    return Intl.NumberFormat('en-US', {
      currency: 'USD',
      maximumSignificantDigits: 3,
      notation: 'compact',
      style: 'currency',
    }).format(value / CENTS_IN_DOLLAR);
  } catch (_e) {
    return centsToWholeDollar(value);
  }
};
