// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header__headerBottomBorder___Umq39{border-bottom:1px solid var(--hairline)}.Header__headerContainer___nuCQQ{background-color:var(--primary400);display:flex;flex-direction:column;height:var(--headerHeight);justify-content:flex-end;left:0;padding:0 1rem;position:fixed;top:0;width:100%;z-index:var(--z_layoutHeader)}@media only screen and (min-width: 390px){.Header__headerContainer___nuCQQ{padding:0 2rem}}@media only screen and (min-width: 768px){.Header__headerContainer___nuCQQ{padding:0 2.5rem}}.Header__headerContents___jqAQD{align-items:center;display:flex;justify-content:space-between;padding-bottom:1.25rem;width:100%}@media only screen and (min-width: 768px){.Header__headerContents___jqAQD{padding-bottom:1rem}}.Header__logo___wVO34{height:2rem;width:29.28px;stroke:var(--accent300)}.Header__nav___vmOT6{align-items:center;display:flex;flex-direction:row;gap:1.5rem;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/webReservations/layout/Header.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,oCACE,uCAAA,CAGF,iCACE,kCAAA,CACA,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,wBAAA,CACA,MAAA,CACA,cAAA,CACA,cAAA,CACA,KAAA,CACA,UAAA,CACA,6BAAA,CCgBE,0CD3BJ,iCAcI,cAAA,CAAA,CCiBA,0CD/BJ,iCAkBI,gBAAA,CAAA,CAIJ,gCACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,sBAAA,CACA,UAAA,CCIE,0CDTJ,gCAQI,mBAAA,CAAA,CAIJ,sBACE,WAAA,CACA,aAAA,CACA,uBAAA,CAGF,qBACE,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,UAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerBottomBorder": "Header__headerBottomBorder___Umq39",
	"headerContainer": "Header__headerContainer___nuCQQ",
	"headerContents": "Header__headerContents___jqAQD",
	"logo": "Header__logo___wVO34",
	"nav": "Header__nav___vmOT6"
};
export default ___CSS_LOADER_EXPORT___;
