// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReceiptDetails__container___Mt7yu{display:flex;flex-direction:column;width:272px;border-top:1px solid var(--hairline);padding-top:40px;margin-top:40px}@media only screen and (max-width: 720px){.ReceiptDetails__container___Mt7yu{width:unset}}.ReceiptDetails__title___cYV1L{margin:0}.ReceiptDetails__receiptDetails___Tf8Z4{display:flex;justify-content:space-between;align-items:center;margin-top:32px}.ReceiptDetails__receiptDetails___Tf8Z4 .ReceiptDetails__receiptDetailName___HoivA{color:var(--white70)}.ReceiptDetails__receiptDetails___Tf8Z4 .ReceiptDetails__receiptDetailValue___ziEGL{color:#fff}", "",{"version":3,"sources":["webpack://./src/webReservations/reservationDetails/ReceiptDetails.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,mCACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,oCAAA,CACA,gBAAA,CACA,eAAA,CCQE,0CDdJ,mCASI,WAAA,CAAA,CAIJ,+BACE,QAAA,CAGF,wCACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,eAAA,CAEA,mFACE,oBAAA,CAGF,oFACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReceiptDetails__container___Mt7yu",
	"title": "ReceiptDetails__title___cYV1L",
	"receiptDetails": "ReceiptDetails__receiptDetails___Tf8Z4",
	"receiptDetailName": "ReceiptDetails__receiptDetailName___HoivA",
	"receiptDetailValue": "ReceiptDetails__receiptDetailValue___ziEGL"
};
export default ___CSS_LOADER_EXPORT___;
