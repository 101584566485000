// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimeFilterPill__pill___kJgsY{align-items:center;background-color:var(--darkBlue);border-radius:100px;border:1px solid rgba(0,0,0,0);color:var(--white);cursor:pointer;display:flex;margin:0;padding:8px 16px}.TimeFilterPill__isOpen___h2pdf,.TimeFilterPill__pill___kJgsY:active,.TimeFilterPill__pill___kJgsY:focus,.TimeFilterPill__pill___kJgsY:hover{border:1px solid var(--gold)}.TimeFilterPill__icon___cvflq{stroke:var(--primary200);width:24px;min-width:24px;margin-right:8px}.TimeFilterPill__phoneLinkText___TuvZv{color:var(--white70)}", "",{"version":3,"sources":["webpack://./src/webReservations/filters/TimeFilterPill.scss"],"names":[],"mappings":"AAAA,8BACE,kBAAA,CACA,gCAAA,CACA,mBAAA,CACA,8BAAA,CACA,kBAAA,CACA,cAAA,CACA,YAAA,CACA,QAAA,CACA,gBAAA,CAGF,6IAIE,4BAAA,CAGF,8BACE,wBAAA,CACA,UAAA,CACA,cAAA,CACA,gBAAA,CAGF,uCACE,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pill": "TimeFilterPill__pill___kJgsY",
	"isOpen": "TimeFilterPill__isOpen___h2pdf",
	"icon": "TimeFilterPill__icon___cvflq",
	"phoneLinkText": "TimeFilterPill__phoneLinkText___TuvZv"
};
export default ___CSS_LOADER_EXPORT___;
