import { UNAUTHORIZED } from '@shared/statusCodes';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import type { UserAccountData } from '../accounts/apiHelpers';
import { getUserAccountData } from '../accounts/apiHelpers';
import { createResponseError } from '../errors/ErrorHandler';

export const userAccountLoader = async (): Promise<UserAccountData> => {
  const response = await getUserAccountData();

  if (isSuccessResponse(response)) {
    return response;
  }

  if (response.statusCode !== UNAUTHORIZED) {
    throw createResponseError(response);
  }

  return {} as UserAccountData;
};
