// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoTabContentWide__slide___pO1_x{display:flex;flex-direction:row;column-gap:3rem;margin-bottom:5rem;max-width:var(--pageContentMaxWidth)}.InfoTabContentWide__slide___pO1_x>div{display:flex;flex-direction:column;align-items:center;justify-content:center;flex-basis:50%}.InfoTabContentWide__slide___pO1_x>div>button{border:none;cursor:pointer;display:flex;flex-direction:column;justify-content:flex-start;text-align:start;background-color:rgba(0,0,0,0);border-radius:8px;padding:1rem 1.5rem}.InfoTabContentWide__slide___pO1_x>div>button.InfoTabContentWide__active___TV8IP{background-color:var(--darkGrey300)}.InfoTabContentWide__slide___pO1_x>div>button:hover{background-color:var(--primary300)}.InfoTabContentWide__slide___pO1_x>div img{width:100%}", "",{"version":3,"sources":["webpack://./src/webReservations/landing/InfoTabContentWide.scss"],"names":[],"mappings":"AAEA,mCACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,kBAAA,CACA,oCAAA,CAEA,uCACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CAEA,8CACE,WAAA,CACA,cAAA,CACA,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,gBAAA,CACA,8BAAA,CACA,iBAAA,CACA,mBAAA,CAEA,iFACE,mCAAA,CAGF,oDACE,kCAAA,CAIJ,2CACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide": "InfoTabContentWide__slide___pO1_x",
	"active": "InfoTabContentWide__active___TV8IP"
};
export default ___CSS_LOADER_EXPORT___;
