import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import typography from '~styles/typography.scss';
import {
  AuthModalTypes,
  useAuthModalContext,
} from '../context/authModalContext';
import { useDrawerContext } from '../context/drawerContext';
import { useUserContext } from '../context/userContext';
import { SignOutModal } from '../auth/SignOutModal';
import {
  ACCOUNT_BALANCE_PATH,
  ACCOUNT_NOTIFICATIONS_PATH,
  OFFERS_ROOT_PATH,
  RESERVATIONS_ROOT_PATH,
} from '../paths';
import styles from './Drawer.scss';

export const Drawer = () => {
  const {
    isOpen: isSignOutModalOpen,
    close: closeSignOutModal,
    open: openSignOutModal,
  } = useIsOpen();
  const { isAuthenticated } = useUserContext();
  const { openModal } = useAuthModalContext();
  const { isOpen, closeDrawer } = useDrawerContext();
  const { ref } = useHandleClickOutside(closeDrawer);

  const navLinkClassNames = ({ isActive }: { isActive: boolean }) =>
    cx({
      [styles.navLink]: true,
      [typography.body]: true,
      [styles.linkActive]: isActive,
    });

  const containerClassNames = cx({
    [styles.container]: true,
    [styles.isOpen]: isOpen,
    [styles.isClosed]: !isOpen,
  });

  const drawerClassNames = cx({
    [styles.drawer]: true,
    [styles.drawerOpen]: isOpen,
    [styles.drawerClosed]: !isOpen,
  });

  return (
    <>
      <div className={containerClassNames} data-testid="drawer">
        <div className={drawerClassNames} ref={ref}>
          <NavLink
            className={navLinkClassNames}
            onClick={closeDrawer}
            end
            to={RESERVATIONS_ROOT_PATH}
          >
            Reservations
          </NavLink>
          <NavLink
            className={navLinkClassNames}
            onClick={closeDrawer}
            end
            to={OFFERS_ROOT_PATH}
          >
            Offers
          </NavLink>
          {isAuthenticated && (
            <>
              <NavLink
                className={navLinkClassNames}
                onClick={closeDrawer}
                end
                to={ACCOUNT_NOTIFICATIONS_PATH}
              >
                Notifications
              </NavLink>
              <NavLink
                className={navLinkClassNames}
                onClick={closeDrawer}
                end
                to={ACCOUNT_BALANCE_PATH}
              >
                Balance
              </NavLink>
            </>
          )}

          <div className={styles.authActions}>
            {isAuthenticated ? (
              <Button
                className={styles.authButton}
                label="Sign Out"
                onClick={() => {
                  closeDrawer();
                  openSignOutModal();
                }}
                useDeprecatedBreakpoint
                variant={ButtonVariants.Default}
              />
            ) : (
              <Button
                label="Login"
                onClick={() => {
                  closeDrawer();
                  openModal(AuthModalTypes.SIGN_IN);
                }}
                useDeprecatedBreakpoint
                variant={ButtonVariants.Primary}
              />
            )}
          </div>
        </div>
      </div>
      <SignOutModal
        closeModal={closeSignOutModal}
        isOpen={isSignOutModalOpen}
      />
    </>
  );
};
