import cx from 'classnames';
import typography from '~styles/typography.scss';
import styles from './SavedPaymentMethod.scss';

const CC_NUMBER_COUNT = 16;
const CC_EXP_MONTH_TARGET_LENGTH = 2;
const CC_EXP_MONTH_PAD_STRING = '0';
const CC_EXP_YEAR_CHARS_TO_SLICE_FROM_END = -2;

interface SavedPaymentMethodProps {
  savedPaymentMethod: {
    brand: string;
    expirationMonth: number;
    expirationYear: number;
    id: string;
    last4: string;
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedPaymentMethodId?: string;
}

export const SavedPaymentMethod = ({
  onChange,
  savedPaymentMethod: { brand, expirationMonth, expirationYear, id, last4 },
  selectedPaymentMethodId,
}: SavedPaymentMethodProps) => (
  <div className={styles.paymentMethodContainer} key={id}>
    <label className={styles.paymentMethod} htmlFor={id}>
      <span>
        <input
          checked={id === selectedPaymentMethodId}
          id={id}
          name="paymentMethod"
          onChange={onChange}
          type="radio"
          value={id}
        />
        <span className={cx(typography.t1, styles.brand)}>{brand}</span>
      </span>
      <span className={typography.t1}>
        {last4.padStart(CC_NUMBER_COUNT, '•')}
      </span>
      <span className={cx(typography.t1, styles.expiration)}>
        Expires{' '}
        {`${expirationMonth
          .toString()
          .padStart(
            CC_EXP_MONTH_TARGET_LENGTH,
            CC_EXP_MONTH_PAD_STRING,
          )}/${expirationYear
          .toString()
          .slice(CC_EXP_YEAR_CHARS_TO_SLICE_FROM_END)}`}
      </span>
    </label>
  </div>
);
