import { Navigate, Outlet } from 'react-router-dom';
import { useOfferCheckoutContext } from '../context/offerCheckoutContext';
import { useUserContext } from '../context/userContext';
import { LANDING_PATH } from '../paths';

export const OfferCheckoutLayout = () => {
  const { isAuthenticated } = useUserContext();
  const { offerCheckout } = useOfferCheckoutContext();

  if (!isAuthenticated || !offerCheckout) {
    return <Navigate to={LANDING_PATH} />;
  }

  return <Outlet />;
};
