// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BankAccountModal__container___y_FkC{padding:16px;text-align:left;display:flex;flex-direction:column}@media only screen and (min-width: 720px){.BankAccountModal__container___y_FkC{width:752px;height:400px;text-align:center;align-items:center}}.BankAccountModal__agreementMessage___aVgHq{margin-top:8px;margin-bottom:8px;color:var(--white70)}.BankAccountModal__links___AZ3c_{color:var(--white);text-align:left;align-items:left}.BankAccountModal__iAgreeButton___Dq3fb{margin-top:16px;width:100%}@media only screen and (min-width: 720px){.BankAccountModal__iAgreeButton___Dq3fb{width:60%;align-items:center;justify-content:center;margin-top:64px}}.BankAccountModal__disconnectBankAccountModalActionContainer___dPPkT{display:flex;flex-direction:column;margin-top:64px}.BankAccountModal__disconnectBankAccountModalMessage___Ze1w3{color:var(--white70)}.BankAccountModal__keepBankAccountConnectedButton___F3uOt{color:var(--gold);background:none;margin-top:12px}.BankAccountModal__keepBankAccountConnectedButton___F3uOt:hover{text-decoration:none;color:var(--gold);background:none !important}", "",{"version":3,"sources":["webpack://./src/webReservations/accounts/BankAccountModal.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,qCACE,YAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CCkBE,0CDtBJ,qCAOI,WAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CAAA,CAIJ,4CACE,cAAA,CACA,iBAAA,CACA,oBAAA,CAGF,iCACE,kBAAA,CACA,eAAA,CACA,gBAAA,CAGF,wCACE,eAAA,CACA,UAAA,CCNE,0CDIJ,wCAKI,SAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CAAA,CAIJ,qEACE,YAAA,CACA,qBAAA,CACA,eAAA,CAGF,6DACE,oBAAA,CAGF,0DACE,iBAAA,CACA,eAAA,CACA,eAAA,CAEA,gEACE,oBAAA,CACA,iBAAA,CACA,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BankAccountModal__container___y_FkC",
	"agreementMessage": "BankAccountModal__agreementMessage___aVgHq",
	"links": "BankAccountModal__links___AZ3c_",
	"iAgreeButton": "BankAccountModal__iAgreeButton___Dq3fb",
	"disconnectBankAccountModalActionContainer": "BankAccountModal__disconnectBankAccountModalActionContainer___dPPkT",
	"disconnectBankAccountModalMessage": "BankAccountModal__disconnectBankAccountModalMessage___Ze1w3",
	"keepBankAccountConnectedButton": "BankAccountModal__keepBankAccountConnectedButton___F3uOt"
};
export default ___CSS_LOADER_EXPORT___;
