import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import type { IRestaurantDetails } from 'webReservations/restaurant/apiHelpers';

export interface IRestaurantContext {
  restaurantDetails: IRestaurantDetails;
  setRestaurantDetails: Dispatch<SetStateAction<IRestaurantDetails>>;
}

const RestaurantContext = createContext<IRestaurantContext>(
  {} as IRestaurantContext,
);
RestaurantContext.displayName = 'Restaurant';

export const useRestaurantContext = () => useContext(RestaurantContext);

interface RestaurantContextProviderProps {
  children: ReactNode;
}

export const RestaurantContextProvider = ({
  children,
}: RestaurantContextProviderProps) => {
  const [restaurantDetails, setRestaurantDetails] =
    useState<IRestaurantDetails>({} as IRestaurantDetails);

  const value = useMemo<IRestaurantContext>(
    () => ({
      restaurantDetails,
      setRestaurantDetails,
    }),
    [restaurantDetails],
  );

  return (
    <RestaurantContext.Provider value={value}>
      {children}
    </RestaurantContext.Provider>
  );
};
