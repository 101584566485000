import API from '../api/apiClient';

interface GuestCounts {
  minGuests: number;
  maxGuests: number;
}

export type AvailableDatesForRestaurant = Record<string, GuestCounts>;

export const getAvailableDatesForRestaurant = async (
  restaurantId: string,
): Promise<AvailableDatesForRestaurant> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/available-dates`,
  );
  return response.json();
};
