// eslint-disable-next-line  no-useless-escape
const EMAIL_REGEX = /^[a-zA-Z0-9_\.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]{2,}$/g;

export const isValidEmail = (email: unknown): boolean =>
  typeof email === 'string' ? Boolean(email.match(EMAIL_REGEX)) : false;

// For use with react-hook-form
export const isValidEmailRule = (value = '') => {
  const trimmedValue = value.trim();
  if (!trimmedValue) {
    return true;
  }

  return isValidEmail(trimmedValue) || 'Must be a valid email address';
};
