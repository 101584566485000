// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Partners__section___bAxgX{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:3rem 1rem;background-color:var(--primary500);z-index:-3}@media only screen and (min-width: 768px){.Partners__section___bAxgX{padding:3rem 2.5rem}}.Partners__section___bAxgX>div{display:flex;flex-direction:column;align-items:center;justify-content:center;row-gap:1rem;max-width:var(--pageContentMaxWidth)}@media only screen and (min-width: 768px){.Partners__section___bAxgX>div{flex-direction:row;column-gap:2rem}}.Partners__section___bAxgX h2{text-align:center;margin:0 0 3rem}", "",{"version":3,"sources":["webpack://./src/webReservations/landing/Partners.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,2BACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,kCAAA,CACA,UAAA,CC4BE,0CDnCJ,2BAUI,mBAAA,CAAA,CAGF,+BACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,oCAAA,CCgBA,0CDtBF,+BASI,kBAAA,CACA,eAAA,CAAA,CAIJ,8BACE,iBAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "Partners__section___bAxgX"
};
export default ___CSS_LOADER_EXPORT___;
