import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FeatureFlagContextProvider } from '@shared/context/featureFlagContext';
import { AuthModalContextProvider } from './context/authModalContext';
import { AvailabilityContextProvider } from './context/availabilityContext';
import { DrawerContextProvider } from './context/drawerContext';
import { ModalContextProvider } from './context/modalContext';
import { OfferCheckoutContextProvider } from './context/offerCheckoutContext';
import { PlaidContextProvider } from './context/plaidContext';
import { RestaurantContextProvider } from './context/restaurantContext';
import { UserContextProvider } from './context/userContext';
import { ScrollToTop } from './components/ScrollToTop';
import { featureFlagConfig } from './featureFlags';
import { AuthModalsContainer } from './modal/AuthModalsContainer';
import { ModalsContainer } from './modal/ModalsContainer';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.overrides.css';
import '../index.scss';

export const Root = () => (
  <FeatureFlagContextProvider environmentConfig={featureFlagConfig}>
    <UserContextProvider>
      <RestaurantContextProvider>
        <AvailabilityContextProvider>
          <OfferCheckoutContextProvider>
            <AuthModalContextProvider>
              <ModalContextProvider>
                <DrawerContextProvider>
                  <PlaidContextProvider>
                    <ScrollToTop>
                      <Outlet />
                      <ToastContainer
                        position="top-right"
                        autoClose={false}
                        newestOnTop
                        limit={1}
                        closeButton
                        hideProgressBar
                        pauseOnHover
                        theme="colored"
                      />
                    </ScrollToTop>
                  </PlaidContextProvider>
                </DrawerContextProvider>
                <ModalsContainer />
              </ModalContextProvider>
              <AuthModalsContainer />
            </AuthModalContextProvider>
          </OfferCheckoutContextProvider>
        </AvailabilityContextProvider>
      </RestaurantContextProvider>
    </UserContextProvider>
  </FeatureFlagContextProvider>
);
