// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OfferDetails__container___DdhTt{display:flex;flex-direction:column;width:272px;border-bottom:1px solid var(--hairline);padding-bottom:40px;margin-bottom:40px}@media only screen and (max-width: 720px){.OfferDetails__container___DdhTt{width:unset}}.OfferDetails__container___DdhTt button{margin-top:16px}.OfferDetails__container___DdhTt button:first-of-type{margin-top:32px}.OfferDetails__title___rkvt5{margin:0}.OfferDetails__offerDetail___OHHPL{display:flex;justify-content:space-between;align-items:center;margin-top:32px}.OfferDetails__offerDetail___OHHPL .OfferDetails__offerDetailName___RDk19{color:var(--white70)}.OfferDetails__offerDetail___OHHPL .OfferDetails__offerDetailValue___vKx5P{color:#fff}", "",{"version":3,"sources":["webpack://./src/webReservations/offers/OfferDetails.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,iCACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,uCAAA,CACA,mBAAA,CACA,kBAAA,CCQE,0CDdJ,iCASI,WAAA,CAAA,CAGF,wCACE,eAAA,CAGF,sDACE,eAAA,CAIJ,6BACE,QAAA,CAGF,mCACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,eAAA,CAEA,0EACE,oBAAA,CAGF,2EACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OfferDetails__container___DdhTt",
	"title": "OfferDetails__title___rkvt5",
	"offerDetail": "OfferDetails__offerDetail___OHHPL",
	"offerDetailName": "OfferDetails__offerDetailName___RDk19",
	"offerDetailValue": "OfferDetails__offerDetailValue___vKx5P"
};
export default ___CSS_LOADER_EXPORT___;
