import { Modal as MUIModal } from '@mui/base/Modal';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { Icon } from '@components/icon/Icon';
import { Backdrop } from '@components/modal/Backdrop';
import { useModalContext } from '../context/modalContext';
import styles from './Modal.scss';

interface ModalProps {
  children: ReactNode;
  label: string;
  onClose?: () => void;
}

export const Modal = ({ children, label, onClose }: ModalProps) => {
  const { closeModal } = useModalContext();

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    closeModal();
  };

  return (
    <MUIModal
      className={cx(styles.modal, styles.modalStack)}
      onClose={handleCloseModal}
      open
      role="main"
      slotProps={{ backdrop: { className: styles.backdrop } }}
      slots={{ backdrop: Backdrop }}
    >
      <div aria-label={label} className={styles.modalContent} role="dialog">
        <div className={styles.closeControl}>
          <button
            aria-label="Close modal"
            className={styles.closeButton}
            onClick={handleCloseModal}
            type="button"
          >
            <Icon name="close" className={styles.closeIcon} />
          </button>
        </div>
        {children}
      </div>
    </MUIModal>
  );
};
