// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SetListPriceModal__heading___ph9hs{margin-bottom:16px;text-align:center}.SetListPriceModal__mobileLineBreak___P1vPb{display:none}@media only screen and (max-width: 720px){.SetListPriceModal__mobileLineBreak___P1vPb{display:inline}}.SetListPriceModal__instructions___iMm5c{color:var(--primary100);text-align:center}", "",{"version":3,"sources":["webpack://./src/webReservations/reservations/SetListPriceModal.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,oCACE,kBAAA,CACA,iBAAA,CAGF,4CACE,YAAA,CCQE,0CDTJ,4CAII,cAAA,CAAA,CAIJ,yCACE,uBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "SetListPriceModal__heading___ph9hs",
	"mobileLineBreak": "SetListPriceModal__mobileLineBreak___P1vPb",
	"instructions": "SetListPriceModal__instructions___iMm5c"
};
export default ___CSS_LOADER_EXPORT___;
