import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { centsToWholeDollar } from '@utils/currency';
import { UpdateListPriceModal } from 'webReservations/reservations/UpdateListPriceModal';
import typography from '~styles/typography.scss';
import { ModalTypes, useModalContext } from '../context/modalContext';
import styles from './ListPrice.scss';

interface ListPriceProps {
  fetchReservation: () => void;
  price: number | null;
  reservationId: string;
}

export const ListPrice = ({
  fetchReservation,
  price,
  reservationId,
}: ListPriceProps) => {
  const { openModal } = useModalContext();
  const {
    isOpen: isListingPriceModalOpen,
    open: openListingPriceModal,
    close: closeListingPriceModal,
  } = useIsOpen();

  return price ? (
    <>
      <div
        className={styles.updateListPrice}
        onClick={openListingPriceModal}
        role="button"
        tabIndex={0}
      >
        <h3 className={cx(typography.h7m, styles.heading)}>
          Update List Price
        </h3>
        <div className={cx(typography.h7m, styles.listPriceAmount)}>
          {centsToWholeDollar(price)}
        </div>
      </div>
      <UpdateListPriceModal
        listPrice={price}
        onSubmit={fetchReservation}
        reservationId={reservationId}
        isOpen={isListingPriceModalOpen}
        closeModal={closeListingPriceModal}
      />
    </>
  ) : (
    <div
      className={styles.setListPrice}
      onClick={() =>
        openModal(ModalTypes.SET_LIST_PRICE, {
          onSubmit: fetchReservation,
          reservationId,
        })
      }
      role="button"
      tabIndex={0}
    >
      <div className={styles.instructionsContainer}>
        <h3 className={cx(typography.h7m, styles.heading)}>List Reservation</h3>
        <p className={cx(typography.t2, styles.instructions)}>
          Set a sales price and we&apos;ll handle the rest.
        </p>
      </div>
      <div className={styles.navigation}>
        <Icon className={styles.navIcon} name="chevron" />
      </div>
    </div>
  );
};
