import { useEffect, useState } from 'react';
import { useRestaurantContext } from '../context/restaurantContext';
import type { AvailableDatesForRestaurant } from '../filters/apiHelpers';
import { getAvailableDatesForRestaurant } from '../filters/apiHelpers';
import { useIsMounted } from './useIsMounted';

export const useAvailableDates = () => {
  const { restaurantDetails } = useRestaurantContext();
  const isMounted = useIsMounted();
  const [availableDates, setAvailableDates] =
    useState<AvailableDatesForRestaurant>({});

  useEffect(() => {
    if (restaurantDetails?.id) {
      const fetchAvailableDatesForRestaurant = async () => {
        const response = await getAvailableDatesForRestaurant(
          restaurantDetails?.id,
        );

        if (isMounted()) {
          setAvailableDates(response);
        }
      };
      fetchAvailableDatesForRestaurant();
    }
  }, [restaurantDetails?.id]);

  return { availableDates };
};
