// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubNavLink__button___DPMQ9{background-color:rgba(0,0,0,0);color:var(--white);border:none;cursor:pointer;height:100%;width:88px;padding:8px;margin-right:16px;display:flex;align-items:center;justify-content:center}@media only screen and (min-width: 720px){.SubNavLink__button___DPMQ9:hover{border-bottom:2px solid var(--primary200);padding-bottom:6px}}.SubNavLink__active___Jhm0c{border-bottom:2px solid var(--accent300);padding-bottom:6px}.SubNavLink__active___Jhm0c:hover{border-bottom:2px solid var(--accent300);padding-bottom:6px}.SubNavLink__helpButton___OH9c_{margin-left:auto;margin-right:0}.SubNavLink__helpButton___OH9c_ .SubNavLink__helpText___sy50t{margin-left:8px}", "",{"version":3,"sources":["webpack://./src/webReservations/restaurant/SubNavLink.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,4BACE,8BAAA,CACA,kBAAA,CACA,WAAA,CACA,cAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CCWE,0CDRA,kCACE,yCAAA,CACA,kBAAA,CAAA,CAKN,4BACE,wCAAA,CACA,kBAAA,CAEA,kCACE,wCAAA,CACA,kBAAA,CAIJ,gCACE,gBAAA,CACA,cAAA,CAEA,8DACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "SubNavLink__button___DPMQ9",
	"active": "SubNavLink__active___Jhm0c",
	"helpButton": "SubNavLink__helpButton___OH9c_",
	"helpText": "SubNavLink__helpText___sy50t"
};
export default ___CSS_LOADER_EXPORT___;
