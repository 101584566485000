import type { ApiResponse } from '@shared/types/apiHelpers';
import API from './api/apiClient';

export interface IPricingInfo {
  fee: number;
  tax: number;
  total: number;
}

export const getPricingInfo = async ({
  price,
  zipCode,
}: {
  price: number;
  zipCode: string;
}): Promise<ApiResponse<IPricingInfo>> => {
  const response = await API.get(
    `/pricing-info?price=${price}&zipCode=${zipCode}`,
  );

  return response.json();
};
