import cx from 'classnames';
import { type FormEventHandler, type ReactNode } from 'react';
import AccentLogo from '@assets/icons/accent-logo.svg';
import { Modal } from '@components/modal/Modal';
import typography from '~styles/typography.scss';
import { useAuthModalContext } from '../context/authModalContext';
import styles from './AuthModal.scss';

export interface AuthModalProps {
  children: ReactNode;
  label: string;
  submitHandler: FormEventHandler<HTMLFormElement>;
  title: string;
}

export const AuthModal = ({
  children,
  label,
  submitHandler,
  title,
}: AuthModalProps) => {
  const { closeModal } = useAuthModalContext();

  return (
    <Modal ariaLabel={label} isOpen onClose={closeModal}>
      <form
        aria-label="auth-modal-form"
        className={styles.form}
        onSubmit={submitHandler}
      >
        <AccentLogo role="presentation" />
        <h1 className={cx(typography.h4)}>{title}</h1>
        {children}
      </form>
    </Modal>
  );
};
