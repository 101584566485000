// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListPrice__setListPrice___QdANS{cursor:pointer;display:flex;justify-content:space-between;padding:8px;padding-right:0}.ListPrice__setListPrice___QdANS .ListPrice__instructionsContainer___xbCLU{margin:8px}.ListPrice__setListPrice___QdANS .ListPrice__instructionsContainer___xbCLU .ListPrice__instructions___XMdxo{color:var(--primary100);margin-top:8px;margin-bottom:0}.ListPrice__setListPrice___QdANS .ListPrice__navigation___YqAcX{border-left:2px solid var(--hairline);display:flex;align-items:center}.ListPrice__setListPrice___QdANS .ListPrice__navigation___YqAcX .ListPrice__navIcon___g96qW{height:24px;width:24px;stroke:var(--accent300);stroke-width:1.5px;transform:scaleX(-1);margin:0 8px}.ListPrice__heading___o0Pe_{margin:0}.ListPrice__updateListPrice___QnZY9{cursor:pointer;display:flex;justify-content:space-between;padding:16px}.ListPrice__updateListPrice___QnZY9 .ListPrice__listPriceAmount___qkq0Y{color:var(--accent300)}", "",{"version":3,"sources":["webpack://./src/webReservations/reservationDetails/ListPrice.scss"],"names":[],"mappings":"AAAA,iCACE,cAAA,CACA,YAAA,CACA,6BAAA,CACA,WAAA,CACA,eAAA,CAEA,2EACE,UAAA,CAEA,4GACE,uBAAA,CACA,cAAA,CACA,eAAA,CAIJ,gEACE,qCAAA,CACA,YAAA,CACA,kBAAA,CAEA,4FACE,WAAA,CACA,UAAA,CACA,uBAAA,CACA,kBAAA,CACA,oBAAA,CACA,YAAA,CAKN,4BACE,QAAA,CAGF,oCACE,cAAA,CACA,YAAA,CACA,6BAAA,CACA,YAAA,CAEA,wEACE,sBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setListPrice": "ListPrice__setListPrice___QdANS",
	"instructionsContainer": "ListPrice__instructionsContainer___xbCLU",
	"instructions": "ListPrice__instructions___XMdxo",
	"navigation": "ListPrice__navigation___YqAcX",
	"navIcon": "ListPrice__navIcon___g96qW",
	"heading": "ListPrice__heading___o0Pe_",
	"updateListPrice": "ListPrice__updateListPrice___QnZY9",
	"listPriceAmount": "ListPrice__listPriceAmount___qkq0Y"
};
export default ___CSS_LOADER_EXPORT___;
