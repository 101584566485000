import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import type { Environment } from '../environments';
import { PRODUCTION, STAGING } from '../environments';

const initSentry = (): void => {
  const sentryEnv = process.env.SENTRY_ENVIRONMENT as Environment;

  if ([STAGING, PRODUCTION].includes(sentryEnv || '')) {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      environment: process.env.SENTRY_ENVIRONMENT,
      integrations: [
        Sentry.extraErrorDataIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      tracesSampleRate: 1.0,
    });
  }
};

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export default initSentry;
