// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserEducationModal__container___wqsbW{padding:16px;display:flex;flex-direction:column}@media only screen and (min-width: 768px){.UserEducationModal__container___wqsbW{width:560px;padding:0 48px 48px}}.UserEducationModal__container___wqsbW .UserEducationModal__heading___nGWva{margin-bottom:16px;text-align:center}.UserEducationModal__container___wqsbW .UserEducationModal__list___mY0ZR{padding-inline-start:0}.UserEducationModal__container___wqsbW .UserEducationModal__list___mY0ZR .UserEducationModal__listItem___tsSvN{margin-bottom:16px}.UserEducationModal__container___wqsbW .UserEducationModal__list___mY0ZR .UserEducationModal__listItem___tsSvN .UserEducationModal__image___meHa2{margin-top:24px}.UserEducationModal__container___wqsbW .UserEducationModal__button___Z2C_o{align-self:stretch}", "",{"version":3,"sources":["webpack://./src/webReservations/restaurant/UserEducationModal.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,uCACE,YAAA,CACA,YAAA,CACA,qBAAA,CCgCE,0CDnCJ,uCAMI,WAAA,CACA,mBAAA,CAAA,CAGF,4EACE,kBAAA,CACA,iBAAA,CAGF,yEACE,sBAAA,CAEA,+GACE,kBAAA,CAEA,kJACE,eAAA,CAKN,2EACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UserEducationModal__container___wqsbW",
	"heading": "UserEducationModal__heading___nGWva",
	"list": "UserEducationModal__list___mY0ZR",
	"listItem": "UserEducationModal__listItem___tsSvN",
	"image": "UserEducationModal__image___meHa2",
	"button": "UserEducationModal__button___Z2C_o"
};
export default ___CSS_LOADER_EXPORT___;
