// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoTabsNarrow__section___jYpoo{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:3rem 1rem;row-gap:1rem;width:100%}@media only screen and (min-width: 768px){.InfoTabsNarrow__section___jYpoo{padding:3rem 2.5rem}}.InfoTabsNarrow__section___jYpoo h2{text-align:center;margin:0 0 3rem}.InfoTabsNarrow__section___jYpoo .InfoTabsNarrow__buttonContainer___Jx330{display:flex;flex-direction:row;margin-bottom:2rem;column-gap:1rem}.InfoTabsNarrow__section___jYpoo .InfoTabsNarrow__infoCarousel___nBbQd{overflow:hidden;margin-bottom:1rem}.InfoTabsNarrow__section___jYpoo .InfoTabsNarrow__infoCarousel___nBbQd .InfoTabsNarrow__container___N5SFN{display:grid;grid-auto-flow:column;grid-auto-columns:100%}.InfoTabsNarrow__section___jYpoo .InfoTabsNarrow__dotContainer___eB9ah{display:flex;justify-content:center;align-items:center;column-gap:1rem;margin-bottom:1rem}.InfoTabsNarrow__section___jYpoo .InfoTabsNarrow__dotContainer___eB9ah button{width:8px;height:8px;border-radius:50%;background-color:var(--darkGrey300);cursor:pointer;border:none}.InfoTabsNarrow__section___jYpoo .InfoTabsNarrow__dotContainer___eB9ah button.InfoTabsNarrow__active___I1CpO{background-color:var(--gold)}.InfoTabsNarrow__section___jYpoo .InfoTabsNarrow__button___EA8zX{width:100%}", "",{"version":3,"sources":["webpack://./src/webReservations/landing/InfoTabsNarrow.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,iCACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,UAAA,CC4BE,0CDnCJ,iCAUI,mBAAA,CAAA,CAGF,oCACE,iBAAA,CACA,eAAA,CAGF,0EACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CAGF,uEACE,eAAA,CACA,kBAAA,CAEA,0GACE,YAAA,CACA,qBAAA,CACA,sBAAA,CAIJ,uEACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,kBAAA,CAEA,8EACE,SAAA,CACA,UAAA,CACA,iBAAA,CACA,mCAAA,CACA,cAAA,CACA,WAAA,CAEA,6GACE,4BAAA,CAKN,iEACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "InfoTabsNarrow__section___jYpoo",
	"buttonContainer": "InfoTabsNarrow__buttonContainer___Jx330",
	"infoCarousel": "InfoTabsNarrow__infoCarousel___nBbQd",
	"container": "InfoTabsNarrow__container___N5SFN",
	"dotContainer": "InfoTabsNarrow__dotContainer___eB9ah",
	"active": "InfoTabsNarrow__active___I1CpO",
	"button": "InfoTabsNarrow__button___EA8zX"
};
export default ___CSS_LOADER_EXPORT___;
