// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LandingPage__divider___bXGud{height:25vh;width:100%}@media only screen and (min-width: 768px){.LandingPage__divider___bXGud{height:40vh}}.LandingPage__line___LAcBW{border:1px solid var(--hairline);flex-basis:100%;margin:0 1rem}@media only screen and (min-width: 768px){.LandingPage__line___LAcBW{display:none}}", "",{"version":3,"sources":["webpack://./src/webReservations/landing/LandingPage.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,8BACE,WAAA,CACA,UAAA,CCiCE,0CDnCJ,8BAKI,WAAA,CAAA,CAIJ,2BACE,gCAAA,CACA,eAAA,CACA,aAAA,CCuBE,0CD1BJ,2BAMI,YAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "LandingPage__divider___bXGud",
	"line": "LandingPage__line___LAcBW"
};
export default ___CSS_LOADER_EXPORT___;
