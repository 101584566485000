import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { formatPhone } from '@utils/formatPhone';
import typography from '~styles/typography.scss';
import styles from './RestaurantDetails.scss';

export interface RestaurantDetailsProps {
  restaurant: {
    address: string;
    id: string;
    name: string;
    phone: string;
    website: string;
  };
}

export const RestaurantDetails = ({ restaurant }: RestaurantDetailsProps) => (
  <article className={styles.container}>
    <h2 className={cx(styles.title, typography.h4)}>Restaurant Details</h2>
    <a
      className={styles.link}
      href={`https://maps.google.com/?q=${encodeURIComponent(
        restaurant.address,
      )}`}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="address"
    >
      <span className={cx(styles.linkText, typography.t1)}>
        {restaurant.address}
      </span>
      <Icon className={styles.linkIcon} name="link" />
    </a>
    <a
      className={styles.link}
      href={restaurant.website}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="website"
    >
      <span className={cx(styles.linkText, typography.t1)}>
        {restaurant.website}
      </span>
      <Icon className={styles.linkIcon} name="link" />
    </a>
    <a
      className={styles.link}
      href={`tel:${restaurant.phone}`}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="phone"
    >
      <span className={cx(styles.linkText, typography.t1)}>
        {formatPhone(restaurant.phone)}
      </span>
      <Icon className={styles.linkIcon} name="link" />
    </a>
  </article>
);
