import cx from 'classnames';
import { cloneDeep, sortBy } from 'lodash-es';
import {
  type MutableRefObject,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import type { Id } from 'react-toastify';
import { Checkbox } from '@components/checkbox/Checkbox';
import { Spinner } from '@components/Spinner';
import {
  successToast,
  updateToast,
  warningToast,
} from '@components/toasts/Toasts';
import { useFeatureFlagContext } from '@shared/context/featureFlagContext';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { isErrorResponse } from '@shared/types/apiHelpers';
import type { WebReservationsFeatureFlag } from 'webReservations/featureFlags';
import typography from '~styles/typography.scss';
import { useError } from '../hooks/useError';
import {
  getSMSNotificationSettings,
  type NotificationSetting,
  updateSMSNotificationSettings,
} from './apiHelpers';
import styles from './NotificationsPage.scss';

const RESERVATION_REMINDER_MESSAGES = 'reservation reminder messages';

export const generateToastMessage = (
  checkBoxName: string,
  newCheckboxValue: boolean,
): ReactNode => (
  <>
    Text notifications are {newCheckboxValue ? 'enabled' : 'disabled'} for
    <strong className={styles.checkBoxName}> {checkBoxName}</strong>
  </>
);

export const NotificationsPage = () => {
  useDocumentTitle('Notifications | Peak Reservations');
  const { isEnabled } = useFeatureFlagContext<WebReservationsFeatureFlag>();

  const [notifications, setNotifications] = useState<NotificationSetting[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const toastRef = useRef<Id | null>(null);
  const setError = useError();

  const reservationReminderFlagIsOff = !isEnabled('reservationReminderFlag');

  useEffect(() => {
    const fetch = () => {
      void (async () => {
        try {
          const response = await getSMSNotificationSettings();
          if (isErrorResponse(response)) {
            throw response.error;
          }

          setNotifications(sortBy(response.settings, 'name'));
        } catch (e) {
          setError(e);
        }
      })();
    };
    fetch();
  }, []);

  const clearToast = (): void => {
    toastRef.current = null;
  };

  const isIdRef = (
    mysteryRef: MutableRefObject<Id | null>,
  ): mysteryRef is MutableRefObject<Id> =>
    typeof mysteryRef.current === 'string' ||
    typeof mysteryRef.current === 'number';

  const notify = (message: ReactNode) => {
    if (isIdRef(toastRef)) {
      updateToast({ message, onClose: clearToast, toastRef });
      return;
    }

    toastRef.current = successToast({ message, onClose: clearToast });
  };

  const handleCheckboxUpdate = (index: number) => {
    setIsLoading(true);

    const updatedNotifications = cloneDeep(notifications);
    const originalNotification = cloneDeep(notifications);

    const newCheckboxValue = !updatedNotifications[index].enabled;
    const notificationName = updatedNotifications[index].name;

    const toastMessage: ReactNode = generateToastMessage(
      notificationName,
      newCheckboxValue,
    );

    updatedNotifications[index].enabled = newCheckboxValue;
    setNotifications(updatedNotifications);

    void (async () => {
      const response = await updateSMSNotificationSettings({
        settings: updatedNotifications,
      });

      if (!response.ok) {
        setNotifications(originalNotification);
        warningToast({
          message: 'Error: Notification change not saved. Please try again.',
          onClose: clearToast,
        });
        setIsLoading(false);
        return;
      }
      notify(toastMessage);
      setIsLoading(false);
    })();
  };

  return (
    <div className={styles.card}>
      <h4 className={cx(styles.title, typography.h4)}>
        Text Notification Preferences
      </h4>
      <fieldset className={styles.fieldset}>
        <legend>Modify which SMS notifications you wish to receive.</legend>
        {notifications.map(({ name, enabled }, index) => {
          const checkboxStyles = cx(styles.checkbox, {
            [styles.hidden]:
              reservationReminderFlagIsOff &&
              name === RESERVATION_REMINDER_MESSAGES,
          });

          return (
            <Checkbox
              className={checkboxStyles}
              checked={enabled}
              disabled={isLoading}
              key={name}
              label={name}
              onChange={() => {
                handleCheckboxUpdate(index);
              }}
            />
          );
        })}
      </fieldset>
      {isLoading && (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
