interface GetOptions {
  signal?: AbortSignal;
}

export default {
  get: async (path: string, { signal }: GetOptions = {}) =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'GET',
      credentials: 'include',
      signal,
    }),

  post: async <TBody>(path: string, body?: TBody) =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  delete: async (path: string) =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  put: async <TBody>(path: string, body?: TBody) =>
    fetch(`${process.env.API_HOST}${path}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }),
};
