// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OffersLayout__container___W42nF{background-color:var(--primary400);margin:0 auto;max-width:var(--deprecatedPageContentMaxWidth)}@media only screen and (max-width: 720px){.OffersLayout__container___W42nF{align-items:center;display:flex;flex-direction:column;margin:0 16px 16px;padding:0}}@media only screen and (min-width: 390px){.OffersLayout__container___W42nF{margin:0 32px 16px;padding:16px 0}}@media only screen and (min-width: 1280px){.OffersLayout__container___W42nF{margin:32px auto 16px}}@media only screen and (max-width: 720px){.OffersLayout__title___Tip0u{margin-top:32px}}.OffersLayout__tabs___UfIpW{margin-top:24px;border-bottom:1px solid var(--hairline);display:flex;flex-direction:row;column-gap:16px;width:100%}@media only screen and (max-width: 720px){.OffersLayout__tabs___UfIpW{margin-top:0}}.OffersLayout__tab___pCaN1{color:var(--white);padding:16px;margin-bottom:-1px}@media only screen and (max-width: 720px){.OffersLayout__tab___pCaN1{width:100%;text-align:center}}.OffersLayout__tab___pCaN1.OffersLayout__isActive___HR3v8{border-bottom:1px solid var(--accent300)}", "",{"version":3,"sources":["webpack://./src/webReservations/offers/OffersLayout.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,iCACE,kCAAA,CACA,aAAA,CACA,8CAAA,CCWE,0CDdJ,iCAMI,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,SAAA,CAAA,CCQA,0CDlBJ,iCAcI,kBAAA,CACA,cAAA,CAAA,CCeA,2CD9BJ,iCAmBI,qBAAA,CAAA,CCLA,0CDSJ,6BAEI,eAAA,CAAA,CAIJ,4BACE,eAAA,CACA,uCAAA,CACA,YAAA,CACA,kBAAA,CACA,eAAA,CACA,UAAA,CCrBE,0CDeJ,4BASI,YAAA,CAAA,CAIJ,2BACE,kBAAA,CACA,YAAA,CACA,kBAAA,CC/BE,0CD4BJ,2BAMI,UAAA,CACA,iBAAA,CAAA,CAGF,0DACE,wCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OffersLayout__container___W42nF",
	"title": "OffersLayout__title___Tip0u",
	"tabs": "OffersLayout__tabs___UfIpW",
	"tab": "OffersLayout__tab___pCaN1",
	"isActive": "OffersLayout__isActive___HR3v8"
};
export default ___CSS_LOADER_EXPORT___;
