import cx from 'classnames';
import { centsToDollar } from '@utils/currency';
import typography from '~styles/typography.scss';
import styles from './CheckoutReceiptDetails.scss';

interface CheckoutReceiptDetailsProps {
  className?: string;
  fee: number;
  priceLabel: string;
  price: number;
  tax: number;
  total: number;
}

export const CheckoutReceiptDetails = ({
  className,
  fee,
  priceLabel,
  price,
  tax,
  total,
}: CheckoutReceiptDetailsProps) => (
  <div className={className}>
    <div className={cx(styles.row, styles.price)}>
      <div className={typography.labelBold}>{priceLabel}</div>
      <div className={typography.labelBold}>{centsToDollar(price)}</div>
    </div>
    <div className={styles.row}>
      <div className={cx(typography.label, styles.subText)}>Tax</div>
      <div className={cx(typography.label, styles.subText)}>
        {centsToDollar(tax)}
      </div>
    </div>
    <div className={styles.row}>
      <div className={cx(typography.label, styles.subText)}>Processing</div>
      <div className={cx(typography.label, styles.subText)}>
        {centsToDollar(fee)}
      </div>
    </div>
    <div className={cx(styles.row, styles.total)}>
      <div className={typography.headingFour}>Total</div>
      <div className={typography.bodyBold}>{centsToDollar(total)}</div>
    </div>
  </div>
);
