// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__button___hR9Kd{background-color:rgba(0,0,0,0);border-radius:.25rem;border:none;cursor:pointer}.styles__icon___Gci0y{fill:var(--primary200)}.styles__menu___hN_ZM{background-color:var(--darkGrey300);border-radius:.25rem;box-shadow:0 .25rem .5rem #000;padding:.5rem 0;width:min-content}.styles__menuItem___g_i4h{background-color:rgba(0,0,0,0);border:0;color:var(--white);cursor:pointer;white-space:nowrap;padding:.875rem 1.5rem;text-align:left;user-select:none;width:100%}.styles__menuItem___g_i4h:active,.styles__menuItem___g_i4h:focus,.styles__menuItem___g_i4h:hover{background-color:var(--hairline)}.styles__menuItem___g_i4h:disabled{color:var(--darkGrey200);cursor:not-allowed}", "",{"version":3,"sources":["webpack://./src/shared/components/kebab/styles.scss"],"names":[],"mappings":"AAAA,wBACE,8BAAA,CACA,oBAAA,CACA,WAAA,CACA,cAAA,CAGF,sBACE,sBAAA,CAGF,sBACE,mCAAA,CACA,oBAAA,CACA,8BAAA,CACA,eAAA,CACA,iBAAA,CAGF,0BACE,8BAAA,CACA,QAAA,CACA,kBAAA,CACA,cAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,gBAAA,CACA,UAAA,CAEA,iGAGE,gCAAA,CAGF,mCACE,wBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles__button___hR9Kd",
	"icon": "styles__icon___Gci0y",
	"menu": "styles__menu___hN_ZM",
	"menuItem": "styles__menuItem___g_i4h"
};
export default ___CSS_LOADER_EXPORT___;
