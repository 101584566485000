import type { PlaidLinkOptions } from 'react-plaid-link';
import { usePlaidLink } from 'react-plaid-link';
import { useNavigate } from 'react-router-dom';
import { usePlaidContext } from '../context/plaidContext';
import { ACCOUNT_BALANCE_PATH } from '../paths';
import { createLinkedAccount } from './apiHelpers';

interface PlaidLinkProps {
  withRedirect?: boolean;
}

export const PlaidLink = ({ withRedirect = false }: PlaidLinkProps) => {
  const { linkToken } = usePlaidContext();
  const navigate = useNavigate();

  const config: PlaidLinkOptions = {
    onSuccess: async (publicToken, metadata) => {
      const [account] = metadata.accounts;

      await createLinkedAccount(publicToken, account.id);

      navigate(ACCOUNT_BALANCE_PATH, {
        state: { plaidLinkError: false, attemptedToConnectBankAccount: true },
      });
    },
    onExit: (error) => {
      if (error) {
        navigate(ACCOUNT_BALANCE_PATH, {
          state: { plaidLinkError: true, attemptedToConnectBankAccount: true },
        });
      }
      navigate(ACCOUNT_BALANCE_PATH);
    },
    token: linkToken,
    ...(withRedirect ? { receivedRedirectUri: window.location.href } : {}),
  };

  const { open, ready } = usePlaidLink(config);

  if (ready) {
    open();
  }

  return null;
};
