import { DisconnectBankAccountModal } from 'webReservations/accounts/DisconnectBankAccountModal';
import {
  type ModalConfiguration,
  ModalTypes,
  useModalContext,
} from '../context/modalContext';
import { ConnectBankAccountModal } from '../accounts/ConnectBankAccountModal';
import { MobileFiltersModal } from '../filters/MobileFiltersModal';
import { WithdrawOfferModal } from '../offers/WithdrawOfferModal';
import { SetListPriceModal } from '../reservations/SetListPriceModal';
import { UserEducationModal } from '../restaurant/UserEducationModal';

export const ModalsContainer = () => {
  const { modals, closeModal } = useModalContext();

  return (
    <>
      {modals.map(({ modalType, props }: ModalConfiguration) => {
        switch (modalType) {
          case ModalTypes.CONNECT_BANK_ACCOUNT:
            return (
              <ConnectBankAccountModal
                closeModal={closeModal}
                key={JSON.stringify({ modalType, props })}
                {...props}
              />
            );
          case ModalTypes.DISCONNECT_BANK_ACCOUNT:
            return (
              <DisconnectBankAccountModal
                closeModal={closeModal}
                key={JSON.stringify({ modalType, props })}
                {...props}
              />
            );
          case ModalTypes.MOBILE_FILTERS:
            return (
              <MobileFiltersModal
                key={JSON.stringify({ modalType, props })}
                {...props}
              />
            );
          case ModalTypes.SET_LIST_PRICE:
            return (
              <SetListPriceModal
                closeModal={closeModal}
                key={JSON.stringify({ modalType, props })}
                {...props}
              />
            );
          case ModalTypes.WITHDRAW_OFFER:
            return (
              <WithdrawOfferModal
                closeModal={closeModal}
                key={JSON.stringify({ modalType, props })}
                {...props}
              />
            );
          case ModalTypes.USER_EDUCATION:
            return (
              <UserEducationModal
                closeModal={closeModal}
                key={JSON.stringify({ modalType })}
              />
            );
          default:
            throw new Error(`Invalid modal type ${modalType}`);
        }
      })}
    </>
  );
};
