import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

interface OfferCheckoutContextProviderProps {
  children: ReactNode;
}

interface OfferCheckout {
  date: string;
  guestCount: number;
  listingId: string;
  restaurantId: string;
  publicName: string;
  time: string;
}

export interface OfferCheckoutContextType {
  offerCheckout: OfferCheckout;
  setOfferCheckout: Dispatch<SetStateAction<OfferCheckout>>;
}

export const OfferCheckoutContext = createContext<OfferCheckoutContextType>(
  {} as OfferCheckoutContextType,
);
OfferCheckoutContext.displayName = 'OfferCheckout';

export const useOfferCheckoutContext = () => useContext(OfferCheckoutContext);

export const OfferCheckoutContextProvider = ({
  children,
}: OfferCheckoutContextProviderProps) => {
  const [offerCheckout, setOfferCheckout] = useState<OfferCheckout>(
    {} as OfferCheckout,
  );

  const value = useMemo<OfferCheckoutContextType>(
    () => ({
      offerCheckout,
      setOfferCheckout,
    }),
    [offerCheckout, setOfferCheckout],
  );

  return (
    <OfferCheckoutContext.Provider value={value}>
      {children}
    </OfferCheckoutContext.Provider>
  );
};
