import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { MOBILE } from '@shared/styles/breakpoints';
import { PLAID_AUTH_PATH } from 'webReservations/paths';
import typography from '~styles/typography.scss';
import { Modal } from '../modal/Modal';
import styles from './BankAccountModal.scss';

interface ConnectBankAccountModalProps {
  closeModal: () => void;
}

export const ConnectBankAccountModal = ({
  closeModal,
}: ConnectBankAccountModalProps) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: MOBILE });

  return (
    <Modal label="Connect Bank Account" onClose={closeModal}>
      <div className={styles.container}>
        <div>
          <h1 className={typography.d3}>Connect Bank Account</h1>
        </div>
        <div
          className={cx(
            isTabletOrMobile ? typography.c3 : typography.c2,
            styles.agreementMessage,
          )}
        >
          By connecting your bank account and transferring your money out of
          Peak, you agree to the following:
        </div>
        <ul>
          <li>
            <a
              className={cx(typography.h7, styles.links)}
              href="https://stripe.com/legal"
            >
              Stripe Services Agreement
            </a>
          </li>
          <li>
            <a
              className={cx(typography.h7, styles.links)}
              href="https://stripe.com/connect-account/legal/recipient"
            >
              Stripe Recipient Agreement
            </a>
          </li>
        </ul>

        <LinkStyledAsButton
          label="I Agree"
          className={styles.iAgreeButton}
          variant={ButtonVariants.Primary}
          to={PLAID_AUTH_PATH}
          onClick={closeModal}
        />
      </div>
    </Modal>
  );
};
