// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RestaurantDetails__container___PVHv0{display:flex;flex-direction:column;width:272px}@media only screen and (max-width: 720px){.RestaurantDetails__container___PVHv0{width:unset}}.RestaurantDetails__title___JW8ao{margin:0}.RestaurantDetails__link___ql3Pi{display:flex;justify-content:space-between;align-items:center;margin-top:32px}.RestaurantDetails__link___ql3Pi .RestaurantDetails__linkText___ordgo{width:80%;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;color:var(--white70)}.RestaurantDetails__link___ql3Pi .RestaurantDetails__linkIcon___O3hmc{stroke:var(--accent300)}", "",{"version":3,"sources":["webpack://./src/webReservations/reservationDetails/RestaurantDetails.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,sCACE,YAAA,CACA,qBAAA,CACA,WAAA,CCWE,0CDdJ,sCAMI,WAAA,CAAA,CAIJ,kCACE,QAAA,CAGF,iCACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,eAAA,CAEA,sEACE,SAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,oBAAA,CAGF,sEACE,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RestaurantDetails__container___PVHv0",
	"title": "RestaurantDetails__title___JW8ao",
	"link": "RestaurantDetails__link___ql3Pi",
	"linkText": "RestaurantDetails__linkText___ordgo",
	"linkIcon": "RestaurantDetails__linkIcon___O3hmc"
};
export default ___CSS_LOADER_EXPORT___;
