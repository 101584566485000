import cx from 'classnames';
import { formatPhone } from '@utils/formatPhone';
import { useRestaurantContext } from 'webReservations/context/restaurantContext';
import typography from '~styles/typography.scss';
import styles from './MaxGuestCountCopy.scss';

const ADDITIONAL_GUEST_TO_EXCEED_MAXIMUM_GUEST_COUNT = 1;

export const MaxGuestCountCopy = () => {
  const { restaurantDetails } = useRestaurantContext();

  const callRestaurantMessage = `For parties of ${
    restaurantDetails.maxReservationGuests +
    ADDITIONAL_GUEST_TO_EXCEED_MAXIMUM_GUEST_COUNT
  } or more guests,
  please call `;

  return (
    <>
      <h2 className={cx(styles.heading, typography.h7)}>
        Reservations for Large Parties
      </h2>
      <p
        className={cx(typography.c3, styles.maxGuestsMessage)}
        data-testid="max-guest-phone-message"
      >
        {callRestaurantMessage}
        <a
          href={`tel:${restaurantDetails.phone}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className={cx(styles.linkText, typography.t1)}>
            {formatPhone(restaurantDetails.phone)}
          </span>
        </a>
      </p>
    </>
  );
};
