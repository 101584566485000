// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationsPage__card___g0AP4{max-width:560px;background-color:var(--darkBlue);border-radius:8px;padding:32px;width:calc(100% - var(--sidePanelWidth))}@media only screen and (max-width: 720px){.NotificationsPage__card___g0AP4{width:100%}}.NotificationsPage__fieldset___W58qV{display:flex;flex-direction:column}.NotificationsPage__fieldset___W58qV legend{display:none}.NotificationsPage__checkbox___q0uWF{align-items:center;display:flex;margin-bottom:24px;width:100%;text-transform:capitalize}.NotificationsPage__checkbox___q0uWF input{margin-left:auto}.NotificationsPage__hidden___uFDn0{display:none}.NotificationsPage__title___Uj7Gv{margin-top:0;text-align:center}.NotificationsPage__spinnerContainer___eLPNC{display:flex;align-items:center;justify-content:center}.NotificationsPage__checkBoxName___fTrji{text-transform:capitalize}", "",{"version":3,"sources":["webpack://./src/webReservations/accounts/NotificationsPage.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,iCACE,eAAA,CACA,gCAAA,CACA,iBAAA,CACA,YAAA,CACA,wCAAA,CCSE,0CDdJ,iCAQI,UAAA,CAAA,CAIJ,qCACE,YAAA,CACA,qBAAA,CAEA,4CACE,YAAA,CAIJ,qCACE,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,UAAA,CACA,yBAAA,CAEA,2CACE,gBAAA,CAIJ,mCACE,YAAA,CAGF,kCACE,YAAA,CACA,iBAAA,CAGF,6CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,yCACE,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "NotificationsPage__card___g0AP4",
	"fieldset": "NotificationsPage__fieldset___W58qV",
	"checkbox": "NotificationsPage__checkbox___q0uWF",
	"hidden": "NotificationsPage__hidden___uFDn0",
	"title": "NotificationsPage__title___Uj7Gv",
	"spinnerContainer": "NotificationsPage__spinnerContainer___eLPNC",
	"checkBoxName": "NotificationsPage__checkBoxName___fTrji"
};
export default ___CSS_LOADER_EXPORT___;
