import cx from 'classnames';
import {
  type Control,
  type FieldPath,
  type FieldValues,
  type PathValue,
  useController,
  type UseControllerProps,
} from 'react-hook-form';
import ReactPhoneNumberInput, { type Country } from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import typography from '~styles/typography.scss';
import styles from './ControlledFormPhoneInput.scss';
import { FormTooltip } from './FormTooltip';
import { LayoutVariant } from './sharedTypes';
import { ValidationErrorMessage } from './ValidationErrorMessage';
import 'react-phone-number-input/style.css';

export const DEFAULT_COUNTRY: Country = 'US';

export interface ControlledFormPhoneInputProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  autoFocus?: boolean;
  className?: string;
  control: Control<T>;
  customError?: string;
  defaultValue?: PathValue<T, Name>;
  disabled?: boolean;
  label: string;
  name: Name;
  placeholder?: string;
  rules?: UseControllerProps<T, Name>['rules'];
  tooltipText?: string;
  variant?: LayoutVariant;
}

export const ControlledFormPhoneInput = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  autoFocus,
  className,
  control,
  customError,
  label,
  name,
  rules = {},
  disabled,
  placeholder,
  defaultValue,
  tooltipText,
  variant = LayoutVariant.Vertical,
}: ControlledFormPhoneInputProps<T, Name>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    defaultValue,
    name,
    rules: {
      ...rules,
      validate: (newValue: string) =>
        isValidPhoneNumber(newValue) || 'Must be a valid phone number',
    },
  });

  const formOrCustomError =
    error ||
    (customError ? { message: customError, type: 'validate' } : undefined);

  return (
    <div
      className={cx(styles.phoneNumberInputGroup, styles[variant], className)}
    >
      <div className={styles.labelContainer}>
        <label
          className={cx({
            [typography.c2_20]: true,
            [styles.labelError]: !!formOrCustomError,
          })}
          htmlFor={name}
        >
          {label}
        </label>
        <FormTooltip label={name} text={tooltipText} />
      </div>
      <div className={styles.phoneInputAndErrorContainer}>
        <div
          className={cx({
            [styles.phoneInputContainer]: true,
            [styles.phoneInputContainerError]: !!formOrCustomError,
          })}
          data-testid="phone-input-container"
        >
          <ReactPhoneNumberInput
            autoFocus={autoFocus}
            autoComplete="off"
            className={styles.phoneInput}
            data-testid="phone-input"
            defaultCountry={DEFAULT_COUNTRY}
            disabled={disabled}
            id={name}
            international
            name={name}
            onChange={onChange}
            placeholder={placeholder || '+1 000 000 0000'}
            value={value}
          />
        </div>
        <ValidationErrorMessage
          error={formOrCustomError}
          label={label}
          name={name}
        />
      </div>
    </div>
  );
};
