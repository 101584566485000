import { type DependencyList, useEffect, useMemo, useReducer } from 'react';

/**
 * Similar to useEffect.
 * An AbortSignal is provided to the function.
 * The signal is aborted when dependencies change or when the component is removed.
 * If the function's returned promise rejects with an abort error, the error will be ignored.
 *
 * Use this when you have an effect that fetches data and you only want to use the most recent request.
 */
export const useAbortEffect = (
  effect: (signal: AbortSignal) => Promise<void>,
  deps: DependencyList,
): { isPending: boolean } => {
  // https://legacy.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [, forceUpdate] = useReducer((n) => n + 1, 0);

  // By using useMemo, isPending will be true immediately after dependencies
  // change, which can avoid UI flicker and reduce re-rendering.
  const latestState = useMemo(() => {
    const abortController = new AbortController();
    const state = { abortController, isPending: true };
    const promise = effect(abortController.signal);
    void (async () => {
      try {
        await promise;
      } catch (e) {
        if (e instanceof Error && e.name === 'AbortError') return;
        throw e;
      } finally {
        // If this is still the latest state, then the forceUpdate
        // call will cause the change to isPending be rendered.
        // If there is a newer state, then this will have no effect.
        state.isPending = false;
        forceUpdate();
      }
    })();
    return state;
  }, deps);

  useEffect(
    () => () => {
      latestState.abortController.abort();
    },
    [latestState],
  );

  return { isPending: latestState.isPending };
};
