import { useRestaurantContext } from 'webReservations/context/restaurantContext';

export const NoAvailabilityCopy = () => {
  const { restaurantDetails } = useRestaurantContext();

  return (
    <span>
      {restaurantDetails.name} has no availability on this day. Please select a
      different day for more seating options.
    </span>
  );
};
