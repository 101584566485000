import cx from 'classnames';
import { Link } from 'react-router-dom';
import Logo from '@assets/icons/logo.svg';
import { WideView } from '@components/wideView/WideView';
import { Navigation } from '../navigation/Navigation';
import { NavigationMobile } from '../navigation/NavigationMobile';
import { LANDING_PATH } from '../paths';
import styles from './Header.scss';

interface HeaderProps {
  hideBorder?: boolean;
}

export const Header = ({ hideBorder = false }: HeaderProps) => {
  const headerStyle = hideBorder
    ? styles.headerContents
    : cx(styles.headerContents, styles.headerBottomBorder);

  return (
    <div className={styles.headerContainer}>
      <header className={headerStyle}>
        <Link to={LANDING_PATH}>
          <Logo aria-label="Peak homepage link" className={styles.logo} />
        </Link>
        <nav aria-label="primary" className={styles.nav}>
          <WideView
            narrowElement={<NavigationMobile />}
            wideElement={<Navigation />}
          />
        </nav>
      </header>
    </div>
  );
};
