import type { FloorPlanData } from '@shared/types/floorPlans';
import API from '../api/apiClient';

// TODO: Should return Promise<ApiResponse<FloorPlanData>>
export const getFloorPlanForRestaurantId = async (
  restaurantId: string,
): Promise<FloorPlanData> => {
  const response = await API.get(`/restaurants/${restaurantId}/floor-plan`);

  return response.json();
};

// TODO: Should return Promise<ApiResponse<FloorPlanData>>
export const getDynamicFloorPlanForRestaurantId = async (
  restaurantId: string,
): Promise<FloorPlanData> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/floor-plan?dynamic=true`,
  );

  return response.json();
};
