import cx from 'classnames';
import type { FormEvent } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import typography from '~styles/typography.scss';
import {
  AuthModalTypes,
  useAuthModalContext,
} from '../context/authModalContext';
import { AuthModal } from './AuthModal';
import styles from './UnregisteredUserLoginModal.scss';

const noOp = () => null;

interface UnregisteredUserLoginModalProps {
  onAuthSuccess?: () => void;
}

export const UnregisteredUserLoginModal = ({
  onAuthSuccess = noOp,
}: UnregisteredUserLoginModalProps) => {
  const { nextModal } = useAuthModalContext();

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault();

    nextModal(AuthModalTypes.SIGN_UP, { onAuthSuccess });
  };

  return (
    <AuthModal
      label="guest login redirect to register"
      submitHandler={onSubmitHandler}
      title="Claim Guest Account"
    >
      <p className={cx(typography.c2, styles.info)}>
        If you made a reservation by phone, a guest account may have been
        created for you. Please register to claim your account.
      </p>
      <Button
        label="Go to Register"
        type="submit"
        variant={ButtonVariants.Primary}
      />
    </AuthModal>
  );
};
