// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WithdrawOfferModal__container___PvPQQ{align-items:center;display:flex;flex-direction:column;padding:64px;text-align:center;width:512px}@media only screen and (max-width: 720px){.WithdrawOfferModal__container___PvPQQ{align-items:flex-start;padding:24px 0;text-align:left;width:unset}}.WithdrawOfferModal__container___PvPQQ .WithdrawOfferModal__title___T1Owz{margin-top:0;margin-bottom:32px}@media only screen and (max-width: 720px){.WithdrawOfferModal__container___PvPQQ .WithdrawOfferModal__title___T1Owz{margin-bottom:0}}.WithdrawOfferModal__container___PvPQQ .WithdrawOfferModal__info___x29EK{color:var(--white70);margin-top:16px;margin-bottom:0}.WithdrawOfferModal__container___PvPQQ .WithdrawOfferModal__buttonRow___iUmWH{display:flex;flex-direction:row;margin-top:32px;column-gap:16px;align-self:stretch}.WithdrawOfferModal__container___PvPQQ .WithdrawOfferModal__buttonRow___iUmWH .WithdrawOfferModal__button___zhGjJ{flex-grow:1;margin:8px;width:180px;padding:0 16px}@media only screen and (max-width: 720px){.WithdrawOfferModal__container___PvPQQ .WithdrawOfferModal__buttonRow___iUmWH{flex-direction:column;align-items:center}}.WithdrawOfferModal__container___PvPQQ .WithdrawOfferModal__error___ZFe0l{margin-top:16px;color:var(--pink)}", "",{"version":3,"sources":["webpack://./src/webReservations/offers/WithdrawOfferModal.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,uCACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,iBAAA,CACA,WAAA,CCQE,0CDdJ,uCASI,sBAAA,CACA,cAAA,CACA,eAAA,CACA,WAAA,CAAA,CAGF,0EACE,YAAA,CACA,kBAAA,CCHA,0CDCF,0EAKI,eAAA,CAAA,CAIJ,yEACE,oBAAA,CACA,eAAA,CACA,eAAA,CAGF,8EACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CAEA,kHACE,WAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CC3BF,0CDgBF,8EAeI,qBAAA,CACA,kBAAA,CAAA,CAIJ,0EACE,eAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "WithdrawOfferModal__container___PvPQQ",
	"title": "WithdrawOfferModal__title___T1Owz",
	"info": "WithdrawOfferModal__info___x29EK",
	"buttonRow": "WithdrawOfferModal__buttonRow___iUmWH",
	"button": "WithdrawOfferModal__button___zhGjJ",
	"error": "WithdrawOfferModal__error___ZFe0l"
};
export default ___CSS_LOADER_EXPORT___;
