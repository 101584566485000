import type { ApiResponse } from '@shared/types/apiHelpers';
import API from '../api/apiClient';

export enum OfferStatus {
  Active = 'active',
  Accepted = 'accepted',
  Canceled = 'canceled',
}

export interface Offer {
  date: string;
  expiredAt: string;
  guestCount: number;
  id: string;
  listing: {
    id: string;
    publicName: string;
  };
  price: number;
  restaurant: {
    id: string;
    name: string;
  };
  status: OfferStatus;
  transaction?: {
    paymentMethod: string;
  };
  time: string;
}

export interface OfferDetails {
  date: string;
  expiredAt: string;
  guestCount: number;
  id: string;
  listing: {
    id: string;
    publicName: string;
  };
  price: number;
  restaurant: {
    address: string;
    id: string;
    name: string;
    phone: string;
    postalCode: string;
    website: string;
  };
  status: OfferStatus;
  time: string;
  transaction: { paymentMethod: string };
}

export const acceptOffer = async (
  offerId: string,
  reservationId: string,
): Promise<ApiResponse<{ description: string }>> => {
  const response = await API.put(`/offers/${offerId}/accept`, {
    reservationId,
  });
  return response.json();
};

export const cancelOffer = async (
  offerId: string,
): Promise<ApiResponse<{ id: string }>> => {
  const response = await API.put(`/offers/${offerId}/cancel`);
  return response.json();
};

export const getOffer = async (
  offerId: string,
): Promise<ApiResponse<OfferDetails>> => {
  const response = await API.get(`/offers/${offerId}`);
  return response.json();
};

export const getOffersOut = async (): Promise<ApiResponse<Offer[]>> => {
  const response = await API.get('/offers');
  return response.json();
};
