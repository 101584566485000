import ReactGA from 'react-ga4';

export const sendGa4AvailabilityFilterDateGuestStartEvent = ({
  restaurantName,
  date,
  guestCount,
}: {
  restaurantName: string;
  date: string;
  guestCount: number;
}) =>
  ReactGA.event('availability_filter_dateguest_start', {
    restaurant_name: restaurantName,
    date,
    guest_count: guestCount,
  });

export const sendGa4AvailabilityFilterGuestUpdateEvent = ({
  restaurantName,
  guestCount,
}: {
  restaurantName: string;
  guestCount: number;
}) =>
  ReactGA.event('availability_filter_guest_update', {
    restaurant_name: restaurantName,
    guest_count: guestCount,
  });

export const sendGa4AvailabilityFilterDateUpdateEvent = ({
  restaurantName,
  date,
}: {
  restaurantName: string;
  date: string;
}) =>
  ReactGA.event('availability_filter_date_update', {
    restaurant_name: restaurantName,
    date,
  });

export const sendGa4AvailabilityFilterTimeStartEvent = ({
  restaurantName,
}: {
  restaurantName: string;
}) =>
  ReactGA.event('availability_filter_time_start', {
    restaurant_name: restaurantName,
  });

export const sendGa4AvailabilityFilterTimeNotAvailable = ({
  restaurantName,
}: {
  restaurantName: string;
}) =>
  ReactGA.event('availability_filter_time_no_availability', {
    restaurant_name: restaurantName,
  });

export const sendGa4AvailabilityFilterTimeApplyEvent = ({
  restaurantName,
}: {
  restaurantName: string;
}) =>
  ReactGA.event('availability_filter_time_apply', {
    restaurant_name: restaurantName,
  });

export const sendGa4AvailabilityFilterGuestDateTimeStartEvent = ({
  restaurantName,
}: {
  restaurantName: string;
}) =>
  ReactGA.event('availability_filter_guestdatetime_start', {
    restaurant_name: restaurantName,
  });
