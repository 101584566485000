export interface ErrorResponse {
  error: string;
  message: string;
  statusCode: number;
}

export type ApiResponse<TSuccess> = TSuccess | ErrorResponse;

export const isErrorResponse = <T extends object>(
  response: ApiResponse<T>,
): response is ErrorResponse => 'error' in response;

export const isSuccessResponse = <T extends object>(
  response: ApiResponse<T>,
): response is T => !isErrorResponse(response);

export const getErrorResponseMessage = async (
  response: Response,
): Promise<string> => {
  const payload = (await response.json()) as ErrorResponse;

  return payload.message;
};
