import cx from 'classnames';
import { sortBy } from 'lodash-es';
import { type ReactElement } from 'react';
import { ISOTimeTo12HourTime } from '@utils/time';
import styles from './TimeFilterPopover.scss';

interface TimeFilterPopoverProps {
  availableTimes: string[];
  className?: string;
  noTimes?: ReactElement;
  selectedTime?: string;
  setSelectedTime: (time: string) => void;
}

interface serviceWindow {
  start: number;
  end: number;
}

const SERVICE_WINDOWS = {
  breakfast: {
    start: new Date().setHours(4, 0, 0),
    end: new Date().setHours(11, 30, 0),
  },
  lunch: {
    start: new Date().setHours(11, 30, 0),
    end: new Date().setHours(16, 0, 0),
  },
};

const FOUR_PM_HOUR = 16;
const HIGHER_SORTING_PRIORITY = 0;
const LOWER_SORTING_PRIORITY = 1;

export const TimeFilterPopover = ({
  availableTimes,
  className,
  noTimes,
  selectedTime,
  setSelectedTime,
}: TimeFilterPopoverProps) => {
  if (!availableTimes.length) {
    return (
      <div className={styles.noTimesPopover}>
        {noTimes || 'No times are available.'}
      </div>
    );
  }

  const breakfasts: string[] = [];
  const lunches: string[] = [];
  const dinners: string[] = [];

  const isInServiceWindow = (
    currentAvailability: number,
    service: serviceWindow,
  ): boolean =>
    currentAvailability >= service.start && currentAvailability < service.end;

  availableTimes.forEach((time: string) => {
    const currentAvailability: number = new Date().setHours(
      Number(time.split(':')[0]),
      Number(time.split(':')[1]),
    );

    if (isInServiceWindow(currentAvailability, SERVICE_WINDOWS.breakfast)) {
      breakfasts.push(time);
    } else if (isInServiceWindow(currentAvailability, SERVICE_WINDOWS.lunch)) {
      lunches.push(time);
    } else {
      dinners.push(time);
    }
  });

  const dinnerTimesSortedStartingAt4Pm = sortBy(dinners, (time) => {
    const [hour, minute, second] = time.split(':').map(Number);
    return [
      hour >= FOUR_PM_HOUR ? HIGHER_SORTING_PRIORITY : LOWER_SORTING_PRIORITY,
      hour,
      minute,
      second,
    ];
  });

  const renderAvailabilities = (availabilities: string[]) =>
    availabilities.map((time) => {
      const timeButtonClassNames = cx({
        [styles.isSelected]: time === selectedTime,
        [styles.timeButton]: true,
      });

      return (
        <li key={time}>
          <button
            className={timeButtonClassNames}
            onClick={() => setSelectedTime(time)}
          >
            {ISOTimeTo12HourTime(time)}
          </button>
        </li>
      );
    });

  const renderAvailabilitiesInServiceWindow = (
    availabilitiesList: string[],
    name: string,
  ): ReactElement => (
    <span>
      {!!availabilitiesList.length && (
        <>
          <h2 id={name}>{name}</h2>
          <ol aria-labelledby={name} className={styles.availabilities}>
            {renderAvailabilities(availabilitiesList)}
          </ol>
        </>
      )}
    </span>
  );

  return (
    <div
      data-testid="available-times"
      className={cx(styles.container, className)}
    >
      <div className={styles.serviceWindows}>
        {renderAvailabilitiesInServiceWindow(breakfasts, 'Breakfast')}
        {renderAvailabilitiesInServiceWindow(lunches, 'Lunch')}
        {renderAvailabilitiesInServiceWindow(
          dinnerTimesSortedStartingAt4Pm,
          'Dinner',
        )}
      </div>
    </div>
  );
};
