// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SavedPaymentMethod__brand___LoKuB{text-transform:capitalize}.SavedPaymentMethod__expiration___OwrwY{text-align:right}.SavedPaymentMethod__paymentMethod___ZrQAc{align-items:center;display:flex;justify-content:space-between}.SavedPaymentMethod__paymentMethod___ZrQAc input{margin-right:11px}.SavedPaymentMethod__paymentMethod___ZrQAc input:focus{outline:var(--primary100) auto medium}.SavedPaymentMethod__paymentMethodContainer___iYMGx{border:1px solid rgba(255,255,255,.3);border-radius:5px;margin-bottom:8px;padding:16px}", "",{"version":3,"sources":["webpack://./src/webReservations/checkout/SavedPaymentMethod.scss"],"names":[],"mappings":"AAAA,mCACE,yBAAA,CAGF,wCACE,gBAAA,CAGF,2CACE,kBAAA,CACA,YAAA,CACA,6BAAA,CAEA,iDACE,iBAAA,CAEA,uDACE,qCAAA,CAKN,oDACE,qCAAA,CACA,iBAAA,CACA,iBAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brand": "SavedPaymentMethod__brand___LoKuB",
	"expiration": "SavedPaymentMethod__expiration___OwrwY",
	"paymentMethod": "SavedPaymentMethod__paymentMethod___ZrQAc",
	"paymentMethodContainer": "SavedPaymentMethod__paymentMethodContainer___iYMGx"
};
export default ___CSS_LOADER_EXPORT___;
