// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconButton__button___g3Wpi{cursor:pointer;border:none;border-radius:50%;padding:4px;color:var(--white);background-color:var(--darkGrey300)}@media only screen and (max-width: 720px){.IconButton__button___g3Wpi{background-color:var(--white);filter:drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1))}}.IconButton__button___g3Wpi svg{stroke:#fff;width:24px;height:24px}@media only screen and (max-width: 720px){.IconButton__button___g3Wpi svg{stroke:#000}}.IconButton__button___g3Wpi:active{background-color:var(--accent300)}", "",{"version":3,"sources":["webpack://./src/shared/components/iconButton/IconButton.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,4BACE,cAAA,CACA,WAAA,CACA,iBAAA,CACA,WAAA,CACA,kBAAA,CACA,mCAAA,CCQE,0CDdJ,4BASI,6BAAA,CACA,gDAAA,CAAA,CAGF,gCACE,WAAA,CACA,UAAA,CACA,WAAA,CCFA,0CDDF,gCAMI,WAAA,CAAA,CAIJ,mCACE,iCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "IconButton__button___g3Wpi"
};
export default ___CSS_LOADER_EXPORT___;
