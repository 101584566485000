// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationMobile__button___iacnL{background-color:rgba(0,0,0,0);border:none;padding:0}.NavigationMobile__menuIcon___Y7tlz{fill:var(--white)}.NavigationMobile__closeIcon___geHDq{stroke:var(--white);width:24px;height:24px;stroke-width:2px}", "",{"version":3,"sources":["webpack://./src/webReservations/navigation/NavigationMobile.scss"],"names":[],"mappings":"AAAA,kCACE,8BAAA,CACA,WAAA,CACA,SAAA,CAGF,oCACE,iBAAA,CAGF,qCACE,mBAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "NavigationMobile__button___iacnL",
	"menuIcon": "NavigationMobile__menuIcon___Y7tlz",
	"closeIcon": "NavigationMobile__closeIcon___geHDq"
};
export default ___CSS_LOADER_EXPORT___;
