/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

export enum ModalTypes {
  CONNECT_BANK_ACCOUNT = 'connectBankAccount',
  DISCONNECT_BANK_ACCOUNT = 'disconnectBankAccount',
  MOBILE_FILTERS = 'mobileFilters',
  SET_LIST_PRICE = 'setListPrice',
  WITHDRAW_OFFER = 'withdrawOffer',
  USER_EDUCATION = 'userEducation',
  PROMO_VIDEO = 'promoVideo',
}

export interface ModalConfiguration {
  modalType: ModalTypes;
  props?: any;
}
interface ModalContextType {
  closeModal: () => void;
  modals: ModalConfiguration[];
  /** @deprecated render the `<Modal>` directly. See RefundButton for an example. */
  openModal: (modalType: ModalTypes, props?: any) => void;
}

const ModalContext = createContext<ModalContextType>({} as ModalContextType);
ModalContext.displayName = 'Modal (Web Reservations)';

/** @deprecated render the `<Modal>` directly. See RefundButton for an example. */
export const useModalContext = () => useContext(ModalContext);

interface ModalContextProviderProps {
  children: ReactNode;
}

export const ModalContextProvider = ({
  children,
}: ModalContextProviderProps) => {
  const [modals, setModals] = useState<ModalConfiguration[]>([]);

  const closeModal = () => {
    setModals([]);
  };

  const openModal = (modalType: ModalTypes, props?: any) => {
    setModals([{ modalType, props }]);
  };

  const value = useMemo<ModalContextType>(
    () => ({
      closeModal,
      modals,
      openModal,
    }),
    [modals],
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
