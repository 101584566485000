import cx from 'classnames';
import ReserveYourTable1 from '@assets/images/reserve-your-table-1.svg';
import ReserveYourTable2 from '@assets/images/reserve-your-table-2.svg';
import ReserveYourTable3 from '@assets/images/reserve-your-table-3.svg';
import { Button, ButtonVariants } from '@components/button/Button';
import typography from '~styles/typography.scss';
import { Modal } from '../modal/Modal';
import styles from './UserEducationModal.scss';

interface UserEducationModalProps {
  closeModal: () => void;
}

export const UserEducationModal = ({ closeModal }: UserEducationModalProps) => (
  <Modal label="Reserve Your Table" onClose={closeModal}>
    <div className={styles.container}>
      <h1 className={cx(typography.d3, styles.heading)}>Reserve Your Table</h1>
      <ol className={styles.list}>
        <li className={cx(typography.c2, styles.listItem)}>
          Select a time slot to reserve your table.
          <ReserveYourTable1 className={styles.image} />
        </li>
        <li className={cx(typography.c2, styles.listItem)}>
          From the available tables, select your favorite table.
          <ReserveYourTable2 className={styles.image} />
        </li>
        <li className={cx(typography.c2, styles.listItem)}>
          From the booking options, select one of the following:
          <ReserveYourTable3 className={styles.image} />
        </li>
      </ol>
      <Button
        className={styles.button}
        label="Got it"
        onClick={closeModal}
        useDeprecatedBreakpoint
        variant={ButtonVariants.Primary}
      />
    </div>
  </Modal>
);
