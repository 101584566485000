import {
  CardElement as StripeCardElementComponent,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import type {
  CreatePaymentMethodData,
  StripeCardElement,
  StripeCardElementChangeEvent,
} from '@stripe/stripe-js';
import { type ChangeEvent, type FormEvent, useEffect, useState } from 'react';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import type { PaymentMethod } from './apiHelpers';
import { getPaymentMethods } from './apiHelpers';

export const usePaymentMethods = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState('');
  const [name, setName] = useState('');
  const [setupFutureCardUsage, setSetupFutureCardUsage] = useState(true);
  const [isCardElementFormComplete, setIsCardElementFormComplete] =
    useState(false);
  const [checkoutResponseError, setCheckoutResponseError] = useState<
    string | undefined
  >();

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      const paymentMethodsResponse = await getPaymentMethods();

      if (isSuccessResponse(paymentMethodsResponse)) {
        setPaymentMethods(paymentMethodsResponse);
        setSelectedPaymentMethodId(paymentMethodsResponse?.[0]?.id);
      }
      setIsLoading(false);
    };
    fetchInitialData();
  }, []);

  const isNewPaymentFormComplete = !!name && isCardElementFormComplete;
  const canCheckOut = selectedPaymentMethodId || isNewPaymentFormComplete;
  const isPayButtonDisabled = isPaymentProcessing || !canCheckOut;

  const handleOnChangeName = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setCheckoutResponseError(undefined);
    setName(value);
  };

  const handleOnChangeCardElement = ({
    complete,
  }: StripeCardElementChangeEvent) => {
    if (complete) {
      setIsCardElementFormComplete(complete);
    }
    setCheckoutResponseError(undefined);
  };

  const processPaymentMethod = async (event: FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return undefined;
    }

    setIsPaymentProcessing(true);
    let stripeResponse;
    if (!selectedPaymentMethodId) {
      stripeResponse = await stripe.createPaymentMethod({
        billing_details: {
          name,
        },
        card: elements.getElement(
          StripeCardElementComponent,
        ) as StripeCardElement,
        type: 'card',
        allow_redisplay: setupFutureCardUsage ? 'always' : 'limited',
      } as CreatePaymentMethodData);

      if (stripeResponse.error) {
        setIsPaymentProcessing(false);
        setCheckoutResponseError(stripeResponse.error.message);
        return undefined;
      }
    }

    return stripeResponse;
  };

  const handleOnError = (message: string) => {
    setIsPaymentProcessing(false);
    setCheckoutResponseError(message);
  };

  const handleOnChangePaymentMethod = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentMethodId(value);
    setCheckoutResponseError(undefined);
  };

  const handleOnChangeSaveCard = () => {
    setSetupFutureCardUsage(!setupFutureCardUsage);
  };

  return {
    checkoutResponseError,
    handleOnChangeCardElement,
    handleOnChangeName,
    handleOnChangePaymentMethod,
    handleOnChangeSaveCard,
    handleOnError,
    isLoading,
    isPayButtonDisabled,
    paymentMethods,
    processPaymentMethod,
    selectedPaymentMethodId,
    setupFutureCardUsage,
  };
};
