import {
  format,
  formatISO,
  getDay,
  isSameDay,
  isToday,
  parseISO,
} from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

type DaysOfWeek = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat';

const DAY_INDEX_MAP: Record<string, DaysOfWeek> = {
  '0': 'Sun',
  '1': 'Mon',
  '2': 'Tue',
  '3': 'Wed',
  '4': 'Thu',
  '5': 'Fri',
  '6': 'Sat',
};
export const DAYS_IN_WEEK = 7;

/**
 * @example '2022-04-10'
 */
export const toISODateFormat = (date: Date): string =>
  formatISO(date, { representation: 'date' });

export const ISODateToWeekDay = (date: string) => getDay(parseISO(date));

/**
 * @param repeat e.g., ['0', '1', '2', '3', '4', '5', '6']
 *
 * @example 'Weekly on Sun, Mon'
 * @example 'Daily'
 */
export const formatWeekdayList = (repeat: string[]): string =>
  repeat.length === DAYS_IN_WEEK
    ? 'Daily'
    : `Weekly on ${repeat
        .map((dayIndex) => DAY_INDEX_MAP[dayIndex])
        .join(', ')}`;

/**
 * Converts an ISO timestamp to a short date string (e.g. "Fri, Jan 26").
 * Returns "Today" if the given timestamp is today. A timezone may optionally
 * be provided for determining today.
 */
export const isoToShortDate = (isoDate: string, timezone?: string): string => {
  const date = parseISO(isoDate);
  const now = timezone ? toZonedTime(new Date(), timezone) : new Date();
  if (isSameDay(date, now)) {
    return 'Today';
  }
  return format(date, 'EEE, MMM d');
};

/**
 * @example '2022-03-10'
 */
export const todayAsShortLocal = (): string => toISODateFormat(new Date());

export const todayInTimezone = (timezone: string): string =>
  toISODateFormat(toZonedTime(new Date(), timezone));

/**
 * @example 'January 1, 2012'
 */
export const toMonthDateAndYear = (date: Date): string =>
  format(date, 'LLLL d, yyyy');

/**
 * @param date ISO date string – e.g., '2012-01-01T12:00:00'
 *
 * @example 'Jan 1, 2012'
 * @example 'Today'
 */
export const toShortDateFormat = (date: string, formatToday = true): string => {
  const parsedDate = parseISO(date);
  if (formatToday)
    return isToday(parsedDate) ? 'Today' : format(parsedDate, 'MMM d, yyyy');

  return format(parsedDate, 'MMM d, yyyy');
};

/**
 * @param startDate e.g., '2012-01-01'
 * @param endDate e.g., '2012-01-02' or null
 *
 * @example 'Jan 1, 2012 – Jan 2, 2012'
 * @example 'Jan 1, 2012 – Endless'
 */
export const toShortDateRange = (
  startDate: string,
  endDate: string | null,
): string =>
  `${toShortDateFormat(startDate)} – ${
    endDate ? toShortDateFormat(endDate) : 'Endless'
  }`;

/**
 * @example 'Jan 1, 2012, 2:00 PM'
 */
export const toShortDateTime = (date: Date): string => format(date, 'PPp');

/**
 * @example 'Sun, Jan 1'
 * @example 'Today'
 */
export const toShortWeekDateFormat = (date: Date): string =>
  isToday(date) ? 'Today' : format(date, 'EEE, MMM d');

/**
 * @param date e.g., '2022-03-10'
 * @param time e.g., '09:00:00' <- Don't forget the leading zero!
 * @param timeZone e.g., 'America/New_York'
 */
export const toUTCDateTime = (
  date: string,
  time: string,
  timeZone: string,
): Date => fromZonedTime(`${date} ${time}`, timeZone);

/**
 * @example 'Sun, January 1, 2012'
 */
export const toWeekdayMonthDateAndYear = (date: Date): string =>
  format(date, 'EEE, LLLL d, yyyy');

/**
 * @example 'Sun, Jan 1, 2012'
 */
export const toWeekdayShortMonthDateAndYear = (date: Date): string =>
  format(date, 'EEE, MMM d, yyyy');

const daysOfWeekShort = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'] as const;
const daysOfWeekMed = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
] as const;

/** Example: "Sun, Tue, Wed" */
export const formatWeekDays = (repeat: string[]) => {
  if (repeat.length === 0) {
    return '';
  }
  const useShort = repeat.length > 3;
  const daysOfWeek = useShort ? daysOfWeekShort : daysOfWeekMed;
  return repeat
    .toSorted()
    .map((r) => daysOfWeek[parseInt(r, 10)])
    .join(', ');
};
