import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DEFAULT_COORDINATES = 0;

export const ScrollToTop = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(DEFAULT_COORDINATES, DEFAULT_COORDINATES);
  }, [location]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return children ? <>{children}</> : null;
};
