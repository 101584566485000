import cx from 'classnames';
import { Button, ButtonVariants } from '@components/button/Button';
import { useFeatureFlagContext } from '@shared/context/featureFlagContext';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import type { WebReservationsFeatureFlag } from 'webReservations/featureFlags';
import typography from '~styles/typography.scss';
import { AcceptTopOffer } from './AcceptTopOffer';
import type { ReservationWithDetails, TopOffer } from './apiHelpers';
import { GuestCancelConfirmationModal } from './GuestCancelConfirmationModal';
import { ListPrice } from './ListPrice';
import { ReceiptDetails } from './ReceiptDetails';
import styles from './ReservationDetailsPanel.scss';
import { RestaurantDetails } from './RestaurantDetails';

interface ReservationDetailsPanelProps {
  fetchReservation: () => void;
  reservation: ReservationWithDetails;
  reservationId: string;
  topOffer: TopOffer;
}

export const ReservationDetailsPanel = ({
  fetchReservation,
  reservation,
  reservationId,
  topOffer,
}: ReservationDetailsPanelProps) => {
  const { isEnabled } = useFeatureFlagContext<WebReservationsFeatureFlag>();
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useIsOpen();

  const guestCancelReservationFlagIsOn = isEnabled(
    'guestCancelReservationFlag',
  );
  const isConfirmed = reservation.status === 'CONFIRMED';

  return (
    <>
      {reservation.status === 'CONFIRMED' && (
        <article className={styles.sellItContainer}>
          <h2 className={cx(typography.h4, styles.sellIt)}>Sell It</h2>
          <div className={styles.card}>
            <AcceptTopOffer offer={topOffer} reservation={reservation} />
          </div>
          <div className={styles.card}>
            <ListPrice
              fetchReservation={fetchReservation}
              price={reservation.buyNowPrice}
              reservationId={reservationId}
            />
          </div>
        </article>
      )}
      <RestaurantDetails restaurant={reservation.restaurant} />
      <ReceiptDetails reservation={reservation} />
      {guestCancelReservationFlagIsOn && isConfirmed && (
        <>
          <Button
            className={styles.cancelButton}
            isDisabled={!reservation.isCancelableByGuest}
            label="Cancel Reservation"
            onClick={openModal}
            useDeprecatedBreakpoint
            variant={ButtonVariants.Tertiary}
          />
          <GuestCancelConfirmationModal
            closeModal={closeModal}
            isOpen={isModalOpen}
            reservation={reservation}
            refreshReservation={fetchReservation}
            cancellationPolicy={reservation.transaction.cancellationPolicy}
          />
        </>
      )}
    </>
  );
};
