import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { NOT_FOUND } from '@shared/statusCodes';
import type { ErrorResponse } from '@shared/types/apiHelpers';
import { NotFoundPage } from './NotFound/NotFoundPage';
import { SomethingWentWrongHero } from './SomethingWentWrongHero';
import { SomethingWentWrongPage } from './SomethingWentWrongPage';

export class ResourceNotFoundError extends Error {
  constructor() {
    super();
    this.name = 'ResourceNotFoundError';
  }
}

export const createResponseError = (jsonResponse: ErrorResponse) =>
  jsonResponse.statusCode === NOT_FOUND
    ? new ResourceNotFoundError()
    : new Error(jsonResponse.error);

interface ErrorHandlerProps {
  withoutLayout?: boolean;
}

export const ErrorHandler = ({ withoutLayout = false }: ErrorHandlerProps) => {
  const error = useRouteError();

  useEffect(() => {
    captureException(error);
  }, []);

  if (withoutLayout) return <SomethingWentWrongHero />;

  return error instanceof ResourceNotFoundError ? (
    <NotFoundPage />
  ) : (
    <SomethingWentWrongPage />
  );
};
