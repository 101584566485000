import cx from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { getInitials } from '@utils/formatName';
import typography from '~styles/typography.scss';
import {
  AuthModalTypes,
  useAuthModalContext,
} from '../context/authModalContext';
import { useUserContext } from '../context/userContext';
import {
  ACCOUNT_ROOT_PATH,
  OFFERS_ROOT_PATH,
  RESERVATIONS_ROOT_PATH,
} from '../paths';
import styles from './Navigation.scss';

export const Navigation = () => {
  const { user, isAuthenticated } = useUserContext();
  const { openModal } = useAuthModalContext();

  const navLinkClassNames = (activeLink: boolean) =>
    cx({
      [styles.link]: true,
      [typography.t1]: true,
      [styles.linkActive]: activeLink,
    });

  return (
    <>
      <NavLink
        className={({ isActive }) => navLinkClassNames(isActive)}
        end
        to={RESERVATIONS_ROOT_PATH}
      >
        Reservations
      </NavLink>
      <NavLink
        className={({ isActive }) => navLinkClassNames(isActive)}
        end
        to={OFFERS_ROOT_PATH}
      >
        Offers
      </NavLink>
      {isAuthenticated ? (
        <Link
          className={styles.badge}
          aria-label="My Account"
          to={ACCOUNT_ROOT_PATH}
        >
          {getInitials(user?.firstName, user?.lastName)}
        </Link>
      ) : (
        <div className={styles.container}>
          <Button
            onClick={() => openModal(AuthModalTypes.SIGN_IN)}
            label="Login"
            useDeprecatedBreakpoint
            variant={ButtonVariants.Tertiary}
          />
        </div>
      )}
    </>
  );
};
