import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface PlaidContextProviderProps {
  children: ReactNode;
}

interface PlaidContextType {
  linkToken: string | null;
  setLinkToken: (token: string) => void;
}

const PlaidContext = createContext<PlaidContextType>({
  linkToken: null,
} as PlaidContextType);
PlaidContext.displayName = 'Plaid';

export const usePlaidContext = () => useContext(PlaidContext);

export const PLAID_LINK_TOKEN_KEY = 'plaid-link-token';

export const PlaidContextProvider = ({
  children,
}: PlaidContextProviderProps) => {
  const [linkToken, _setLinkToken] = useState<string | null>(null);

  useEffect(() => {
    _setLinkToken(localStorage.getItem(PLAID_LINK_TOKEN_KEY));
  }, []);

  const value = useMemo<PlaidContextType>(
    () => ({
      linkToken,
      setLinkToken: (token: string) => {
        localStorage.setItem(PLAID_LINK_TOKEN_KEY, token);
        _setLinkToken(token);
      },
    }),
    [linkToken, _setLinkToken],
  );

  return (
    <PlaidContext.Provider value={value}>{children}</PlaidContext.Provider>
  );
};
